import Navigation from "./common/navigation";
import { routePath } from "./constants/routePath";
import About from "./features/about/about";
import Counter from "./features/counter/counter";
import Enquiry from "./features/enquiry/enquiry";
import Feature from "./features/feature/feature";
import FollowUp from "./features/followup/followup";
import Addslide from "./features/home/addslide";
import Home from "./features/home/home";
import Login from "./features/login/login";
// import Projects from "./features/projects/projects";
import Projects_exp from "./features/projects/projects_exp";
import Services from "./features/services/services";
import Testimonials from "./features/testimonials/testimonials";
import VRTour from "./features/vrtour/vrtour";
import VrSettingsPage from "./features/vrtour/VrSettingsPage";

export default [
  {
    path: routePath.ROOT,
    name: "Login",
    Component: Login,
  },
  {
    path: routePath.DASHBOARD,
    name: "Dashboard",
    Component: Navigation,
  },
];

export const DashbordRoutes = [
  {
    path: routePath.HOME,
    name: "Home",
    Component: Home,
  },
  {
    path: routePath.ABOUT,
    name: "About",
    Component: About,
  },
  {
    path: routePath.SERVICES,
    name: "Services",
    Component: Services,
  },
  {
    path: routePath.COUNTER,
    name: "Counter",
    Component: Counter,
  },
  {
    path: routePath.PROJECTS,
    name: "Projects",
    Component: Projects_exp,
  },
  {
    path: routePath.VRTOUR,
    name: "VRTour",
    Component: VRTour,
  },
  {
    path: routePath.ENQUIRY,
    name: "Enquiry",
    Component: Enquiry,
  },
  {
    path: routePath.FOLLOWUP,
    name: "FollowUp",
    Component: FollowUp,
  },
  {
    path: routePath.TESTIMONIALS,
    name: "Testimonials",
    Component: Testimonials,
  },

  {
    path: routePath.ADDSLIDE,
    name: "Addslide",
    Component: Addslide,
  },
  {
    path: routePath.FEATURE,
    name: "Feature",
    Component: Feature,
  },
  {
    path: routePath.VRSETTINGS,
    name: "VRSetting",
    Component: VrSettingsPage,
  },
];
