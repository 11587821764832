import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Card, CardMedia, Divider, Grid, Typography } from "@mui/material";
import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";
import { PhotoCamera } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import { DOMAIN } from "../../utils/api";
import { vrTourAddAxios, vrTourUpdateAxios } from "../../utils/vrtour/axios";
import UploadAndDisplay from "./UploadAndDisplay";
import { Link, useNavigate } from "react-router-dom";
import { routePath } from "../../constants/routePath";
import VrSettingsPage from "./VrSettingsPage";
import { useDispatch, useSelector } from "react-redux";
import {
  add_project_details,
  clear_Image,
  clear_Image_Url,
  clear_project_details,
} from "../../redux/ImageSlice/ImageSlice";

const Vrtour_form = (props) => {
  const [picEdited, setPicEdited] = useState(false);
  const [picEdited1, setPicEdited1] = useState(false);
  const [picEdited2, setPicEdited2] = useState(false);
  const [imageContainer, setImageContainer] = useState({});
  const [imageContainer2, setImageContainer2] = useState({});
  const [imageContainer3, setImageContainer3] = useState({});

  // const [images, setImages] = useState([]);
  // const [imageURL, setImagesURL] = useState([]);

  // const dataForVrSettings = {
  //   images,
  //   setImages,
  //   imageURL,
  //   setImagesURL,
  // };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    top: false,
  });

  const [formData, setFormData] = useState({
    thumbnail: "",
    preview: "",
    panorama: "",
  });

  const projectDetails = useSelector((state) => state.VRImages.Project_Details);
  const VRimages = useSelector((state) => state.VRImages.Images);

  const clearImageContainers = () => {
    setImageContainer({});
    setImageContainer2({});
    setImageContainer3({});
    setPicEdited(false);
    setPicEdited1(false);
    setPicEdited2(false);
  };

  const clearFormData = () => {
    setFormData({
      thumbnail: "",
      preview: "",
      panorama: "",
    });
  };

  const handleInputChange = (field, value) => {
    const updatedDetails = { ...projectDetails, [field]: value };
    dispatch(add_project_details(updatedDetails));
  };

  const handleNextButtonClick = () => {
    // Check if required fields are filled
    if (
      !projectDetails.title ||
      !projectDetails.thumbnail ||
      !projectDetails.preview ||
      !VRimages[0]?.file
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill all required fields!",
      });
      return;
    }
    navigate("/dashboard/vrtour/vrsettings/");
    clearImageContainers();
    clearFormData();
  };

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            variant="contained"
            onClick={() => {
              props.setDrowerDisplay();
            }}
            endIcon={<AddCircleOutlineIcon />}
          >
            Add VR-Tour
          </Button>
          <Drawer
            anchor={anchor}
            open={props.drawerDisplay}
            onClose={(e) => {
              props.setDrowerDisplay(e);
              clearImageContainers();
              clearFormData();
              // alert('rishi')
              // props.setImages([]);
              // props.setImagesURL([]);
              dispatch(clear_project_details({}));
              dispatch(clear_Image());
              dispatch(clear_Image_Url());
            }}
          >
            <Box sx={{ width: 400 }} role="presentation">
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {" "}
                <Button
                  onClick={(e) => {
                    props.setDrowerDisplay(e);
                    clearImageContainers();
                    clearFormData();
                    dispatch(clear_project_details({}));
                    dispatch(clear_Image([]));
                    dispatch(clear_Image_Url([]));
                  }}
                >
                  <CloseIcon />
                </Button>
              </Box>
              <Grid
                container
                p={3}
                justifyContent="center"
                alignItems="center"
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12}>
                  <TextField
                    type="text"
                    sx={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      width: "100%",
                    }}
                    placeholder="Enter Project Title*"
                    onChange={(e) => handleInputChange("title", e.target.value)}
                  />
                  <Typography gutterBottom variant="body2" component="div">
                    Thumbnail
                  </Typography>
                  <Button sx={{ mb: 2 }} variant="outlined" component="label">
                    Upload <PhotoCamera />*
                    <input
                      onChange={(e) => {
                        let imgD = window.URL.createObjectURL(
                          e.target.files[0]
                        );
                        handleInputChange("thumbnail", imgD);
                        // setImageContainer(imgD);
                        // setPicEdited(true);
                        // setFormData({
                        //   ...formData,
                        //   thumbnail: e.target.files[0],
                        // });
                      }}
                      hidden
                      accept="image/*"
                      type="file"
                    />
                  </Button>
                  <Card>
                    <CardMedia
                      component={`${projectDetails.thumbnail ? "img" : "Box"}`}
                      sx={{ height: "200px" }}
                      image={projectDetails?.thumbnail}
                      title="My Image"
                    />
                  </Card>
                  <Divider />
                  <Typography variant="body2" color={"red"}>
                    Image size should not exceed 2MB.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="body2" component="div">
                    Preview
                  </Typography>
                  <Button sx={{ mb: 2 }} variant="outlined" component="label">
                    Upload <PhotoCamera />*
                    <input
                      onChange={(e) => {
                        let imgD = window.URL.createObjectURL(
                          e.target.files[0]
                        );
                        handleInputChange("panorama", imgD);
                        handleInputChange("preview", imgD);

                        // setImageContainer2(imgD);
                        // setPicEdited1(true);
                        // setFormData({
                        //   ...formData,
                        //   preview: e.target.files[0],
                        // });
                      }}
                      hidden
                      accept="image/*"
                      type="file"
                    />
                  </Button>
                  <Card>
                    <CardMedia
                      component={`${projectDetails.preview ? "img" : "Box"}`}
                      sx={{ height: "200px" }}
                      image={projectDetails?.preview}
                      title="My Image"
                    />
                  </Card>

                  <Divider />
                  <Typography variant="body2" color={"red"}>
                    Image size should not exceed 2MB.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="body2" component="div">
                    Panorama's
                  </Typography>

                  <UploadAndDisplay
                    images={props.images}
                    setImages={props.setImages}
                    setImagesURL={props.setImagesURL}
                    imageURL={props.imageURL}
                  />
                  <Divider />
                  <Typography variant="body2" color={"red"}>
                    Image size should not exceed 5MB.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    sx={{ width: "100%" }}
                    onClick={handleNextButtonClick}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default Vrtour_form;
