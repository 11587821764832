import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Card,
  CardMedia,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";
import { PhotoCamera } from "@mui/icons-material";
import { DOMAIN } from "../../utils/api";
import { projectAddAxios, projectUpdateAxios } from "../../utils/project/axios";

const Project_frm = (props) => {
  const arrayPattern = /^\[.*\]$/;


  const [picEdited, setPicEdited] = useState(false);
  const [imageContainer, setImageContainer] = useState({});

  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedImages,setUploadedImages]=useState([])

  const handleImageChange = (e) => {
    const tempFileArr= [];
    const tempBlogArr = [];

    [...e.target.files].forEach(file => {
      console.log("file>>>", file);
      tempFileArr.push(file);
      tempBlogArr.push(URL.createObjectURL(file));
    });

    setFormData({ ...formData, image:[...tempFileArr]});
    setImages(tempBlogArr);
  };
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    image: [],
  });
  useEffect(()=>{
    if(!props.drawerDisplay){
      setImages([])
      setImageContainer([])
    }
  },[props.drawerDisplay])
  useEffect(() => {
    // setFormData({ ...props.projectId, image: props.projectId.image });
    setFormData({ ...props.projectId, image:[] });
    console.log(formData, "formData");
  }, [props.projectId]);

  const setEverythingEmpty = () => {
    setFormData({ title: "", image: "", description: "" });
    setImageContainer({});
  };

  

  const projectUpdateHandle = async () => {
    const fm = new FormData();
    let params = { ...formData };
    Object.keys(params).forEach((key) => {
      if (key !== 'image') {
        // if (key === 'id') {
        //   fm.append('projectId', params[key]);
        // } else {
          fm.append(key, params[key]);
        // }

      }
    });
    setIsLoading(true)
    let newIndex;
    formData.image.forEach((imageFile, index) => {
      fm.append(`image[${index}]`, imageFile);
      newIndex = index+1;
    });
    // props.projectId.image.forEach((imageFile, index) => {
    //   let newImageFile = generateBlob(imageFile);
    //   fm.append(`image[${index+newIndex}]`, newImageFile);
    // })

    await projectUpdateAxios(fm)
      .then((res) => {
        props.projectFetchData();
        props.setDrowerDisplay();
        props.setDisabledSubButton(false);
        Swal.fire({
          icon: "success",
          title: "Project updated successfully",
        });

        let data = { ...formData };
        delete data["id"];
        setFormData(data);
        setFormData({ title: "", image: "", description: "" });
        setImageContainer({});
        setIsLoading(false)
      })
      .catch((err) => {
        console.log("err ", err);
        setIsLoading(false)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
        });
      });
  };



  const projectAddHandle = async () => {
    if (formData.title === "") {
      Swal.fire({
        icon: "error",
        title: "Please enter a title",
      });
      props.setDrowerDisplay();
    } else if (formData.description === "") {
      Swal.fire({
        icon: "error",
        title: "Please enter a description",
      });
      props.setDrowerDisplay();
    } 
    else if ( formData.image.length=== 0) {
      Swal.fire({
        icon: "error",
        title: "Please select an image",
      });
      props.setDrowerDisplay();
    }
    const fm = new FormData();
    let params = { ...formData };
    Object.keys(params).forEach((key) => {
      if (key !== 'image') {
        fm.append(key, params[key]);
      }
    });
  
    // Append each image file to FormData
    formData.image.forEach((imageFile, index) => {
      fm.append(`image[${index}]`, imageFile);
    });
    setIsLoading(true)
    await projectAddAxios(fm)
      .then((res) => {
        props.projectFetchData();
        props.setDrowerDisplay();
        props.setDisabledSubButton(false);
        Swal.fire({
          icon: "success",
          title: "Project added successfully",
          // text: "Added successfully",
        });
        setFormData({ title: "", image: "", description: "" });
        setImageContainer({});
        setIsLoading(false)
      })
      .catch((err) => {
        console.log("err ", err);
        setIsLoading(false)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
        });
      });
  };



  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            variant="contained"
            onClick={() => {
              props.setIsEdit(false);
              props.setDrowerDisplay();
              props.setDisabledSubButton(false);
            }}
            endIcon={<AddCircleOutlineIcon />}
          >
            Add Project
          </Button>
          <Drawer
            anchor={anchor}
            open={props.drawerDisplay}
            onClose={(e) => {
              props.setDrowerDisplay(e);
              setEverythingEmpty();
            }}
          >
            <Box sx={{ width: 400 }} role="presentation">
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {" "}
                <Button
                  onClick={() => {
                    props.setDrowerDisplay();
                    setEverythingEmpty();
                  }}
                >
                  <CloseIcon />
                </Button>
              </Box>
              <Grid
                container
                p={3}
                justifyContent="center"
                alignItems="center"
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12}>
                  <TextField
                    sx={{ width: "100%" }}
                    id="outlined-basic"
                    label="Title*"
                    variant="outlined"
                    value={formData.title}
                    onChange={(e) => {
                      setFormData({ ...formData, title: e.target.value });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ width: "100%" }}
                    id="outlined-multiline-static"
                    label="Description*"
                    multiline
                    rows={4}
                    value={formData.description}
                    onChange={(e) => {
                      setFormData({ ...formData, description: e.target.value });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button sx={{ mb: 2 }} variant="outlined" component="label">
                    Upload
                    <PhotoCamera />
                    {/* <input
                      onChange={(e) => {
                        let imgD = URL.createObjectURL(e.target.files[0]);
                        setImageContainer(imgD);
                        setPicEdited(true);
                        setFormData({ ...formData, image: e.target.files[0] });
                      }}
                      hidden
                      accept="image/*"
                      type="file"
                    /> */}
                     <input
                      onChange={handleImageChange}
                      accept="image/*"
                      hidden
                      type="file"
                      multiple // Allows multiple file selection
                    />


                  </Button>
                  <Typography variant="body2" color={'#ff0000'}>*First selected image will be display as thumbnail image & Image size should not exceed 2MB.</Typography>

                
                </Grid>
                <Grid item xs={12}>
                  <div>
                  {/* {formData.image.length>0 &&
                      formData.image.map((item,index)=>{
                        return <img key={"upoad"+index} src={DOMAIN+item} style={{height:'200px',width:'100%'}} alt={`Image ${index + 1}`} />
                      }) 
                    } */}
                    {images.map((image, index) => (
                      <img key={index} src={image} alt={`Image ${index + 1}`} style={{height:'200px',width:'100%'}}/>
                    ))}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Button
                   disabled={isLoading ? true : false}
                    onClick={(e) => {
                      props.setIsEdit(false);
                      formData.id
                        ? projectUpdateHandle(e)
                        : projectAddHandle(e);
                      props.setDisabledSubButton(true);
                    }}
                    variant="contained"
                    sx={{ width: "100%" }}
                  >
                    {props.isEdit?"Update":"Submit"}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default Project_frm;