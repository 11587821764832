import axios from "../axios";
import { ADDSERVICES, DELETESERVICES, GETSERVICES, UPDATESERVICES } from "./api";

export const servicesGetAxios = async () => { 
    const res = await axios.get(GETSERVICES);
    return res;
  };
  
  export const servicesAddAxios = async (params) => {
   
    const res = await axios.post(ADDSERVICES, params);
    return res;
  };
  export const servicesUpdateAxios = async (params) => {
   
    const res = await axios.post(UPDATESERVICES, params);
    return res;
  };
  export const servicesDeleteAxios = async (params) => { 
    const res = await axios.get(DELETESERVICES+'/'+ params);
    return res;
  };