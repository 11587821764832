import React, { useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import {
  followupGetAxios,
  followupUpdateAxios,
} from "../../utils/followup/axios";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { DOMAIN } from "../../utils/api";
import Loader from "../../assets/Loader/preloader.svg";
import NoImg from '../../assets/img/noimg.png'
import { Box } from "@mui/material";

const FollowUp = () => {
  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "name", //access nested data with dot notation
        header: "Name",
        muiTableBodyCellEditTextFieldProps: {
          disabled: true,
          type: "text",
          variant: "outlined",
        },
      },
      {
        accessorKey: "quotes",
        header: "Quote",
        muiTableBodyCellEditTextFieldProps: {
          type: "text",
          variant: "outlined",
        },
      },
      {
        accessorKey: "response", //normal accessorKey
        header: "Response",
        muiTableBodyCellEditTextFieldProps: {
          type: "text",
          variant: "outlined",
        },
      },
      {
        accessorKey: "created_at",
        header: "Follow Up Date",
        muiTableBodyCellEditTextFieldProps: {
          disabled: true,
          type: "text",
          variant: "outlined",
        },
      },
      {
        accessorKey: "filePath",
        Cell: ({ row }) => (
          <img
            style={{ height: "50px", width: "50px", borderRadius: "50%" }}
            src={row.original.filePath ? DOMAIN + row.original.filePath : NoImg}
            alt="profile"
          />
        ),
        header: "Attached File",
        muiTableBodyCellEditTextFieldProps: {
          disabled: true,
          type: "text",
          variant: "outlined",
        },
      },
    ],
    []
  );

  const [data, setData] = useState({
    name: "",
    quotes: "",
    response: "",
    filePath: "",
    created_at: "",
  });
  const [tableData, setTableData] = useState(() => data);
  const [loading, setLoading] = useState(false);

  const handleSaveRow = async (e) => {
    let editupdatedata = {
      ...e.values,
      quotes: e.values.quotes,
      response: e.values.response,
    };
    await e.exitEditingMode(); 
  };

  const UpdateHandle = async (e) => {
    e.exitEditingMode();

    let editupdatedata = {
      ...e.values,
      quote: e.values.quotes,
      response: e.values.response,
      id:e.row.original.id,
      follow_up_id:e.row.original.follow_up_id ,
    };

    
    setLoading(true);

    await followupUpdateAxios(editupdatedata)
      .then((res) => {
        followupFetchData();
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Follow Up Updated Successfully",
        });
        let data = { ...data };
        setData(data);
      })
      .catch((err) => {
        // console.log("err ", err);
      });
  };

  const followupFetchData = async () => {
  

    try {
      const user = await localStorage.getItem("userDetails");
      setLoading(true);
      const res = await followupGetAxios(JSON.parse(user));
      const responseData = res.data;
      setLoading(false);

      const tmArry = [];

      responseData.followUps.forEach((item) => {
        item.follow_ups.forEach((item2) => {
          const modifiedItem = {
            name: item.name,
            id: item.id,
            follow_up_id:item2.id,
            quotes: item2.quotes,
            response: item2.response,
            created_at: item2.created_at.split("T")[0],
            filePath: item2.filePath ? item2.filePath : "",
          };
          tmArry.push(modifiedItem);
        });
      });
      setData(tmArry);
    } catch (err) {
      console.log("Error: ", err);
    }
  };
  useEffect(() => {
    followupFetchData();
  }, []);

  return (
    <>
      {!loading ? (
        data !== null ? (
          <Box sx={{mb:7}}>
            {" "}
            <MaterialReactTable
              sx={{ height: "100%", }}
              columns={columns}
              data={data}
              editingMode="modal" //default
              enableEditing
              onEditingRowSave={UpdateHandle}
            />
          </Box>
        ) : (
          <>
            {" "}
            <p>Error While laoding the data</p>{" "}
          </>
        )
      ) : (
        <>
          <div
            className="d-flex"
            style={{
              zIndex: "-1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <img src={Loader} />
          </div>
        </>
      )}
    </>
  );
};

export default FollowUp;
