import { DashbordRoutes } from "../routes";

export const getTitle = () => {
  const path = window.location.pathname;
  const lastRoute = path.split("/").pop();
  const filteredRoute = DashbordRoutes.filter((item) => {
    return item.path.includes(lastRoute);
  });
  if (filteredRoute.length === 1) {
    return filteredRoute[0].name;
  }
  return false;
};
