import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Grid, IconButton, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2';
import { featureAddAxios, featureUpdateAxios } from '../../utils/features/axios';
const Feature_frm = (props) => {
    const [state, setState] =useState({
        top: false,
    });
    
    const [formData,setFormData] =useState({
        title:'',
        description:''
    })

    useEffect(()=>{
        setFormData(props.featureId)
    },[props.featureId])

    const featureUpdateHandle=async()=>{
        await featureUpdateAxios(formData).then( (res) => {
            props.featureFetchData();
           props.setDrowerDisplay()
           Swal.fire({
            icon: 'success',
            title: 'Feature Updated successfully',
            // text: 'Updated successfully',
          })
        let data={...formData}
        delete data['id']
        setFormData(data);
          })
          .catch((err) => {
            console.log("err ", err);
            // alert
          });
        }

  const featureAddHandle=async()=>{
    if(formData.title===''){
        Swal.fire({
            icon: "error",
            title: "Please enter a title",
          });
          props.setDrowerDisplay();
    }
    else if(formData.description===''){
        Swal.fire({
            icon: "error",
            title: "Please enter a description",
          });
          props.setDrowerDisplay();
    }else{
    await featureAddAxios(formData).then( (res) => {
        props.featureFetchData();
        props.setDrowerDisplay()
        Swal.fire({
            icon: 'success',
            title: 'Feature Added successfully',
            // text: 'Added successfully',
          })
      })
      .catch((err) => {
        console.log("err ", err);
      });
    }
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    

    return (
        <div>
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                    
{
     props.featureArr.length<3 &&
                        <Button variant="contained" onClick={()=>{
                            props.setDrowerDisplay()
                            }} endIcon={<AddCircleOutlineIcon />}>Add Feature</Button>
}
                    <Drawer
                        anchor={anchor}
                        open={props.drawerDisplay}
                        onClose={props.setDrowerDisplay}
                    >
                        
                        <Box
            sx={{ width:  400 }}
            role="presentation"

        >
           <Box sx={{display:'flex',justifyContent:'flex-end'}}> <Button  onClick={props.setDrowerDisplay}><CloseIcon/></Button></Box>
            <Grid container p={3} justifyContent="center" alignItems="center" rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                    <TextField
                    sx={{ width: '100%' }}
                        id="outlined-basic"
                        label="Title*"
                        variant="outlined"
                        value={formData.title}
                        onChange={(e)=>{
                            setFormData({...formData,title:e.target.value})
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                        <TextField
                        sx={{ width: '100%' }}
          id="outlined-multiline-static"
          label="Description*"
          value={formData.description}
          multiline
          rows={4}
          onChange={(e)=>{
            setFormData({...formData,description:e.target.value})
        }}
        />

                </Grid>
                <Grid item xs={12}>
                    <Button onClick={formData.id?featureUpdateHandle:featureAddHandle} variant="contained" sx={{ width: '100%' }}>
                        {props.isEdit? "Update": "Submit"}
                    </Button>
                </Grid>
            </Grid>
        </Box>
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}

export default Feature_frm