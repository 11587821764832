export const routePath={
    ROOT:"/",
    DASHBOARD:"/dashboard/*",
    HOME:"home",
    ABOUT:"about",
    SERVICES:"services",
    COUNTER:"counter",
    PROJECTS:"projects",
    VRTOUR:"vrtour/*",
    ENQUIRY:"enquiry",
    FOLLOWUP:"followup",
    TESTIMONIALS:"testimonials",
    ADDSLIDE:"addslide",
    FEATURE:"feature",
    VRSETTINGS:"vrtour/vrsettings"
} 