import { useNavigate } from "react-router";
import { routePath } from "../../constants/routePath";
import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { loginAxios } from "../../utils/axios";
import Swal from "sweetalert2";
import Logo from "../../assets/img/logo.png";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link
        style={{ color: "#ff9100" }}
        href={"http://www.ekattastudio.com/"}
        target={"_blank"}
      >
        Ekatta Studio
      </Link>{" "}{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const theme = createTheme();
export default function Login() {
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  function handleUnameChange(event) {
    setLoginData({ ...loginData, username: String(event.target.value) });
  }
  function handlePassChange(event) {
    setLoginData({ ...loginData, password: String(event.target.value) });
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    // clickHandler()
  }, []);
  const clickHandler = async () => {
    await loginAxios(loginData)
      .then(async (res) => {
        if (res.data.status === "success") {
          await localStorage.setItem(
            "userDetails",
            JSON.stringify(res.data.authorisation)
          );
          // window.token=res.data.authorisation.token
          navigate(routePath.DASHBOARD);
          Swal.fire({
            icon: "success",
            title: "Login Successfully",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.data.message,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
        });
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      clickHandler();
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box onClick={() => window.location.reload()} component="img" alt="logo" src={Logo} />

            {/* <Typography component="h1" sx={{fontWeight:'bold'}} variant="h5">
            Sign in
          </Typography> */}
            <Box sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={loginData.username}
                onChange={handleUnameChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                value={loginData.password}
                onChange={handlePassChange}
                onKeyPress={handleKeyPress}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                // type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={clickHandler}
              >
                Log In
              </Button>
            </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
