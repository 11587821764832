import React, { useEffect, useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import {
  Box,
  Card,
  CardMedia,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import AddTaskIcon from "@mui/icons-material/AddTask";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "../../utils/axios";
import { GETINQUIRY } from "../../utils/enquiry/api";
import {
  enquiryGetAxios,
  enquiryUpdateAxios,
  responseAddAxios,
} from "../../utils/enquiry/axios";
import Swal from "sweetalert2";
import { PhotoCamera } from "@mui/icons-material";
import { DOMAIN } from "../../utils/api";
import Loader from "../../assets/Loader/preloader.svg";
import moment from "moment";
const Enquiry = () => {
  // modal show/hide
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [picEdited, setPicEdited] = useState(false);
  const [imageContainer, setImageContainer] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isResponseOn, setIsResponseOn] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "name", //access nested data with dot notation
        header: "Name",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "phoneNumber", //normal accessorKey
        header: "Phone",
      },
      {
        accessorKey: "message",
        header: "Message",
      },
      {
        accessorKey: "created_at",
        header: "Date/Time",
        enableEditing: false,
      },
    ],
    []
  );
  
  const [data, setData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
    created_at:""
  });
  
  


  const [formData, setFormData] = useState({
    name: "",
    message: "",
    quote: "",
    response: "",
    file: "",
  });




  const enquiryFetchData = async () => {
    try {
      const user = await localStorage.getItem("userDetails");
      setLoading(true);
      const response = await enquiryGetAxios(JSON.parse(user));
      const modifiedData = response.data.enquiry.map(enquiry => ({
        ...enquiry,
        created_at: moment(enquiry.created_at).format("DD-MM-YYYY h:mm A")
      }));
      setData(modifiedData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error
    }
  };

  const UpdateHandle = async (e) => {
    e.exitEditingMode();
    let editupdatedata = {
      ...e.values,
      phone: e.values.phoneNumber,
      id: e.row.original.id,
    };
    await enquiryUpdateAxios(editupdatedata)
      .then((res) => {
        enquiryFetchData();
        Swal.fire({
          icon: "success",
          title: "Enquiry updated successfully",
          // text: "Updated successfully",
        });

        let data = { ...data };
        // delete data["id"];
        setData(data);
      })
      .catch((err) => {
        console.log("err ", err);
        // alert
      });
  };

  const responsetAddHandle = async () => {
    const fm = new FormData();
    let params = { ...formData };
    Object.keys(params).forEach((key) => {
      fm.append(key, params[key]);
    });

    if (formData.quote === "") {
      Swal.fire({
        icon: "error",
        title: "Please enter a quote",
      });
      setOpen(false);
    } else if (formData.response === "") {
      Swal.fire({
        icon: "error",
        title: "Please enter a response",
      });
      setOpen(false);
    } else {
      await responseAddAxios(fm)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Added successfully",
          });
          setOpen(false);
        })
        .catch((err) => {
          console.log("err ", err);
          // alert
        });
    }
  };

  useEffect(() => {
    enquiryFetchData();
  }, []);



  return (
    <>
      {!loading ? (
        data !== null ? (
          <>
            <Box sx={{mb:7}}>
           <MaterialReactTable
              columns={columns}
              data={data}
              editingMode="modal" //default
              enableEditing
              onEditingRowSave={UpdateHandle}
              enableRowActions
              renderRowActionMenuItems={({ row, closeMenu }) => {
                return (
                  <MenuItem
                    onClick={(e) => {
                      closeMenu();
                      setOpen(true);
                      setFormData({
                        ...formData,
                        name: row.original.name,
                        message: row.original.message,
                        id: row.original.id,
                      });
                      let tempformData = {
                        ...formData,
                      };
                      tempformData.name = data[row.index].name;
                      tempformData.message = data[row.index].message;
                      setOpen(true);
                    }}
                  >
                    <AddTaskIcon
                      sx={{ paddingRight: "5px", color: "#757575" }}
                    />
                    Response
                  </MenuItem>
                );
              }}
            />
           </Box>

            <Dialog
              fullWidth={fullWidth}
              maxWidth={maxWidth}
              open={open}
              onClose={handleClose}
            >
              <DialogTitle>Follow Up</DialogTitle>
              <DialogContent>
                <TextField
                  disabled
                  value={formData.name}
                  onChange={(e) => {
                    setFormData({ ...formData, name: e.target.value });
                    
                  }}
                  sx={{ width: "100%", margin: "10px 0px" }}
                  id="outlined-multiline-static"
                  label="name"
                />
                <TextField
                  disabled
                  value={formData.message}
                  onChange={(e) => {
                    setFormData({ ...formData, message: e.target.value });
                    
                  }}
                  sx={{ width: "100%", margin: "10px 0px" }}
                  id="outlined-multiline-static"
                  label="Message"
                  multiline
                  rows={4}
                />

                <TextField
                  sx={{ width: "100%", margin: "10px 0px" }}
                  id="outlined-multiline-static"
                  label="Quote*"
                  multiline
                  rows={4}
                  onChange={(e) => {
                    setFormData({ ...formData, quote: e.target.value });
                    
                  }}
                />
                <TextField
                  sx={{ width: "100%", margin: "10px 0px" }}
                  id="outlined-multiline-static"
                  label="Response*"
                  multiline
                  rows={4}
                  onChange={(e) => {
                    setFormData({ ...formData, response: e.target.value });
                    
                  }}
                />

                <Button sx={{ mb: 2 }} variant="outlined" component="label">
                  Upload
                  <PhotoCamera />
                  <input
                    onChange={(e) => {
                      let imgD = URL.createObjectURL(e.target.files[0]);
                      setImageContainer(imgD);
                      setPicEdited(true);
                      setFormData({ ...formData, file: e.target.files[0] });
                    }}
                    hidden
                    accept="image/*"
                    type="file"
                  />
                </Button>
                <Card>
                  <CardMedia
                    sx={{
                      height: "200px",
                      width: "100%",
                      objectFit: "stretch",
                    }}
                    image={picEdited ? imageContainer : DOMAIN + formData.image}
                    title="My Image"
                  />
                </Card>
                <Typography variant="body2" color={"red"}>Image size should not exceed 5MB.</Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={responsetAddHandle}>Submit</Button>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
          </>
        ) : (
          <>
            {" "}
            <p>Error While laoding the data</p>{" "}
          </>
        )
      ) : (
        <>
          <div
            className="d-flex"
            style={{
              zIndex: "-1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <img src={Loader} />
          </div>
        </>
      )}
    </>
  );
};

export default Enquiry;