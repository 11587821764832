import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Images: [],
  ImagesURL: [],
  Project_Details: {
    title: "",
    preview: "",
    panorama: "",
    thumbnail: "",
  },
  idCnt: 0,
};

const ImageSlice = createSlice({
  name: "VRImage",
  initialState,
  reducers: {
    add_image: (state, action) => {
      const data = action.payload;
      state.Images = [...state.Images, ...data];
    },
    add_imageURL: (state, action) => {
      const urlData = action.payload;
      const imageWithId = urlData.map((item) => ({
        id: (state.idCnt += 1),
        file: item,
      }));
      state.ImagesURL = [...imageWithId];
    },
    update_img_title: (state, action) => {
      const { id, title } = action.payload;

      const updatedImages = state.Images.map((item) =>
        item.id === id ? { ...item, title } : item
      );

      state.Images = updatedImages;
    },
    delete_image: (state, action) => {
      const imageIdToDelete = action.payload;
      state.Images = state.Images.filter(
        (image) => image.id !== imageIdToDelete
      );
    },

    add_hotspot: (state, action) => {
      const { currentImageId, imageId, title, pitch, yaw, type } =
        action.payload;

      const imageIndex = state.Images.findIndex(
        (image) => image.id === currentImageId
      );

      if (imageIndex !== -1) {
        state.Images[imageIndex].hotspots =
          state.Images[imageIndex].hotspots || [];

        const newHotSpot = { imageId, title, pitch, yaw, type };
        state.Images[imageIndex].hotspots.push(newHotSpot);
      }
    },
    add_project_details: (state, action) => {
      const newDetails = action.payload;
      state.Project_Details = { ...state.Project_Details, ...newDetails };
    },

    clear_project_details: (state, action) => {
      state.Project_Details = action.payload;
    },
    clear_Image: (state, action)=> {
      state.Images = [];
    },
    clear_Image_Url: (state, action) => {
      state.ImagesURL = [];
    }
  },
});

export const {
  add_image,
  clear_Image,
  add_imageURL,
  clear_Image_Url,
  delete_image,
  update_img_title,
  add_hotspot,
  clear_project_details,
  add_project_details,
} = ImageSlice.actions;
export default ImageSlice.reducer;
