import axios from "../axios";
import { ADDRESP, GETINQUIRY, UPDATEENQUIRY } from "./api";
export const enquiryGetAxios = async () => { 
    const res = await axios.get(GETINQUIRY);
    return res;
  };

  export const responseAddAxios = async (params) => { 
    const res = await axios.post(ADDRESP, params);
    return res;
  };
  export const enquiryUpdateAxios = async (params) => { 
    const res = await axios.post(UPDATEENQUIRY, params);
    return res;
  };