import axios from "../axios";
import { ADDVRINHOME, ADDVRTOUR, DELETEVRTOUR, GETVRTOUR, GETWEBVRTOUR, UPDATEVRTOUR } from "./api";

export const vrTourGetAxios = async () => {
 
    const res = await axios.get(GETVRTOUR);
    return res;
  };
  export const vrTourAddAxios = async (params) => {
   
    const res = await axios.post(ADDVRTOUR, params);
    return res;
  };
  export const vrTourUpdateAxios = async (params) => { 
    const res = await axios.post(UPDATEVRTOUR, params);
    return res;
  };
  export const vrTourDeleteAxios = async (params) => { 
    const res = await axios.get(DELETEVRTOUR+'/'+ params);
    return res;
  };


  export const vrInHomeAddAxios = async (params) => {   
    const res = await axios.post(ADDVRINHOME, params);
    return res;
  };

  export const vrWebTourGetAxios = async () => { 
    const res = await axios.get(GETWEBVRTOUR);
    return res;
  };