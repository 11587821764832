import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Bunglow from "../../assets/img/bunglow.png";
import Office from "../../assets/img/office.png";
import { useNavigate } from "react-router";
import {
  Button,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Divider,
  Typography,
  Stack,
} from "@mui/material";
import Drawer1 from "./drawer";
import { DOMAIN } from "../../utils/api";
import Swal from "sweetalert2";
import { homeDeleteAxios, homeGetAxios } from "../../utils/home/axios";
import { Tooltip } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
export default function Home({ name }) {
  const navigate = useNavigate();
  const [homeArr, sethomeArr] = useState([]);
  const [drawerDisplay, setDrowerDisplay] = useState(false);
  const [isEdit , setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [homeId, sethomeId] = useState({
    id: 0,
    title: "",
    subTitle: "",
    image: "",
    button:""
  });
  const homeFetchData = async () => {
    const data = await localStorage.getItem("userDetails");
    setLoading(true);
    await homeGetAxios(JSON.parse(data))
      .then(async (res) => {
        sethomeArr(res.data.home);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err ", err);
        // alert
      });
  };
  useEffect(() => {
    homeFetchData();
  }, []);
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={6}>
          <Typography gutterBottom variant="h6" component="div">
            {name}
          </Typography>
        </Grid>
        <Grid item xs={6} align="right">
          <Drawer1
            homeId={homeId}
            sethomeId={sethomeId}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            drawerDisplay={drawerDisplay}
            setDrowerDisplay={() => {
              drawerDisplay ? setDrowerDisplay(false) : setDrowerDisplay(true);
            }}
            homeFetchData={homeFetchData}
          />
        </Grid>
      </Grid>

      <Divider />

      <Box sx={{ width: "100%", height:"100%" }} mt={3}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {!loading ? (
            homeArr !== null ? (
              <>
                {" "}
                {homeArr.map((item, index) => (
                  <Grid key={"slidekey" + index} item lg={4} md={6} xs={12}>
                    <Card>
                      <Box sx={{ position: "relative" }}>
                        <CardMedia
                          sx={{
                            height: 200,
                            boxShadow:
                              "inset 0 0 0 2000px rgb(25 118 210 / 37%)",
                          }}
                          image={DOMAIN + item.photoPath}
                          title="bunglow"
                        />
                        <Box
                          sx={{
                            position: "absolute",
                            top: "10px",
                            right: "0",
                            width: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Box>
                              <Typography
                                sx={{ mb: "0", pl: "5px", color: "white" }}
                                gutterBottom
                                variant="h6"
                                component="div"
                              >
                                {item.title}
                              </Typography>
                              <Typography
                                sx={{ mb: "0", pl: "5px", color: "white" }}
                                gutterBottom
                                variant="subtitle1"
                                component="div"
                              >
                                {item.subTitle}
                              </Typography>
                            </Box>
                            <Stack spacing={2} direction="row" flexWrap="nowrap" sx={{pr:2}}>
                              <Tooltip title="Edit" arrow>
                              
                                  <EditIcon sx={{ color: "white", cursor:'pointer' }} onClick={() => {
                                    setDrowerDisplay(true);
                                    setIsEdit(true);
                                    sethomeId({
                                      ...homeId,
                                      id: item.id,
                                      title: item.title,
                                      subTitle: item.subTitle,
                                      image: item.photoPath,
                                      button: 'Update'
                                    });
                                  }}/>
                              </Tooltip>
                              <Tooltip title="Delete" arrow>                              
                                  <DeleteIcon sx={{ color: "white", cursor:'pointer' }}  onClick={async () => {
                                    Swal.fire({
                                      title: "Do you really want to delete?",
                                      showCancelButton: true,
                                      confirmButtonText: "Confirm",
                                    }).then(async (result) => {
                                      if (result.isConfirmed) {
                                        await homeDeleteAxios(item.id)
                                          .then(() => {
                                            Swal.fire(
                                              "Slide Deleted Successfully!",
                                              "",
                                              "success"
                                            );
                                            homeFetchData();
                                          })
                                          .catch((err) => {
                                            console.log("Error", err);
                                            Swal.fire("Oops!", "", err);
                                          });
                                      }
                                    });
                                  }}/>
                              </Tooltip>
                            </Stack>
                          </Box>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                ))}{" "}
              </>
            ) : (
              <>
                {" "}
                <p>Error while loading</p>{" "}
              </>
            )
          ) : (
            <>
              {Array.from({ length: 3 }).map((_, index) => (
                <Grid key={"skeletonKey" + index} item lg={4} md={6} xs={12}>
                  <Card>
                    <Card sx={{ padding: 1, position: "relative" }}>
                      <Skeleton height={190} />
                      <Skeleton height={25} mt={2} style={{ marginTop: 10 }} />
                    </Card>
                  </Card>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Box>
    </>
  );
}
