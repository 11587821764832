import axios from "../axios";
import {
  ADDFEATURES,
  DETELETFEATURE,
  GETFEATURES,
  UPDATEFEATURES,
} from "./api";

export const featureGetAxios = async () => {
  const res = await axios.get(GETFEATURES);
  return res;
};
export const featureAddAxios = async (params) => {
  const res = await axios.post(ADDFEATURES, params);
  return res;
};
export const featureUpdateAxios = async (params) => {
  const res = await axios.post(UPDATEFEATURES, params);
  return res;
};

export const featureDeleteAxios = async (params) => {
  const res = await axios.get(DETELETFEATURE + "/" + params);
  return res;
};
