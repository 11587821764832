import React, { useState, useEffect } from "react";
import { Pannellum } from "pannellum-react";
import { Card, CardMedia, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  add_image,
  add_imageURL,
  update_img_title,
  delete_image,
  add_hotspot,
  clear_project_details,
  clear_Image,
  clear_Image_Url,
} from "../../redux/ImageSlice/ImageSlice";

import { vrTourAddAxios } from "../../utils/vrtour/axios";
import Swal from "sweetalert2";

import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";

import VR1 from "../vrtour/dummy_vr/vr1.png";
import VR2 from "../vrtour/dummy_vr/vr2.png";
import VR3 from "../vrtour/dummy_vr/vr3.png";
import VR4 from "../vrtour/dummy_vr/vr4.png";

import { TrainRounded } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { routePath } from "../../constants/routePath";

const VrSettingsPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const images = useSelector((state) => state.VRImages.Images);
  const reduxState = useSelector((state) => state.VRImages);
  const projectDetails = useSelector((state) => state.VRImages.Project_Details);

  const [currentImage, setCurrentImage] = useState(null);
  const [hotspots, setHotspots] = useState([]);
  const [getPointer, setPointer] = useState(false);
  const [selectedImageId, setImageId] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [hotspotType, setHotspotType] = useState("custom");
  const [yaw, setYaw] = React.useState(0);
  const [pitch, setPitch] = React.useState(0);

  const hotspotTypeArr = [
    { id: 1, type: "custom" },
    { id: 2, type: "info" },
  ];

  const panImage = React.useRef(null);

  const [xyCoordinated, setXYCoordinates] = useState({
    pitch: 0,
    yaw: 0,
  });

  const formatDataForPostRequest = async (images) => {
    if (!images || !Array.isArray(images) || !projectDetails) {
      console.error("Images are null or not an array!");
      return;
    }

    return {
      title: projectDetails.title,
      thumbnail: VR1,
      preview: VR1,
      panorama: VR4,
      vr_tour_scene: images?.map((scene) => ({
        file: scene.file || null,
        imageId: scene.id || null,
        title: scene.title || "",
        hotspots: scene.hotspots?.map((hotspot) => ({
          imageId: hotspot.imageId,
          title: hotspot.title || "",
          pitch: hotspot.pitch || 0,
          yaw: hotspot.yaw || 0,
          type: hotspot.type || "custom",
        })),
      })),
    };
  };

  const vrTourAddHandle = async (e) => {
    const formatedData = await formatDataForPostRequest(images);

    setIsLoading(true);
    const form = new FormData();

    form.append("title", formatedData.title);
    form.append(
      "thumbnail",
      await fetch(projectDetails.thumbnail).then((res) => res.blob(), {
        mode: "no-cors",
      })
    );
    form.append(
      "preview",
      await fetch(projectDetails.preview).then((res) => res.blob(), {
        mode: "no-cors",
      })
    );
    form.append(
      "panorama",
      await fetch(projectDetails.thumbnail).then((res) => res.blob(), {
        mode: "no-cors",
      })
    );

    for (const [index, scene] of formatedData?.vr_tour_scene.entries()) {
      form.append(
        `scenes[${index}][file]`,
        await fetch(scene.file).then((res) => res.blob(), { mode: "no-cors" })
      );
      form.append(`scenes[${index}][imageId]`, scene.imageId);
      form.append(`scenes[${index}][title]`, scene.title);

      for (const [hotspotIndex, hotspot] of scene?.hotspots.entries()) {
        form.append(
          `scenes[${index}][hotspots][${hotspotIndex}][imageId]`,
          hotspot.imageId
        );
        form.append(
          `scenes[${index}][hotspots][${hotspotIndex}][title]`,
          hotspot.title || ""
        );
        form.append(
          `scenes[${index}][hotspots][${hotspotIndex}][pitch]`,
          hotspot.pitch || 0
        );
        form.append(
          `scenes[${index}][hotspots][${hotspotIndex}][yaw]`,
          hotspot.yaw || 0
        );
        form.append(
          `scenes[${index}][hotspots][${hotspotIndex}][type]`,
          hotspot.type || "custom"
        );
      }
    }

    await vrTourAddAxios(form).then((res) => {
      Swal.fire({
        icon: "success",
        title: "VR Added successfully",
        // text: "VR Added successfully",
      });
      setIsLoading(false);
    dispatch(clear_project_details({}));
    dispatch(clear_Image());
    dispatch(clear_Image_Url());
    navigate(routePath.VRTOUR);
    })
    .catch((err) => {
      console.log("err ", err);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err.response.data.message,
      });
      setIsLoading(false);
      dispatch(clear_project_details({}));
      dispatch(clear_Image());
      dispatch(clear_Image_Url());
    });
    
  };

  const handleSelect = (e) => {
    setImageId((prevId) => {
      return e.target.value;
    });
  };

  const handleHotspotTypeChange = (e) => {
    setHotspotType(e.target.value);
  };

  // saving the hotSpots

  const handleSaveHotspot = (e, imgId, Pointer) => {
    if (imgId) {
      const newHotSpot = {
        currentImageId: currentImage.id,
        imageId: selectedImageId,
        title: title,
        pitch: xyCoordinated.pitch,
        yaw: xyCoordinated.yaw,
        type: hotspotType,
      };

      dispatch(add_hotspot(newHotSpot));

      setTitle("");

      setCurrentImage((prevImage) => {
        if (prevImage) {
          const hotspotsArray = prevImage.hotspots || [];

          return {
            ...prevImage,
            hotspots: [...hotspotsArray, newHotSpot],
          };
        }
        return prevImage;
      });
    }
  };

  const generateUniqId = () => {
    const randomTwoDigitNumber = Math.floor(Math.random() * 90) + 10;
    return randomTwoDigitNumber;
  };

  const onImageChange = (e) => {
    const newImages = Array.from(e.target.files).map((image) => ({
      file: window.URL.createObjectURL(image),
      id: generateUniqId(),
    }));

    dispatch(add_image(newImages));
  };

  const handleHotSpotSelection = async (e) => {
    const { clientX, clientY } = e;

    const pitch = panImage.current.getViewer().getPitch();
    const yaw = panImage.current.getViewer().getYaw();;

    setXYCoordinates({
      pitch: pitch,
      yaw: yaw,
    });
    setPointer(true);
  };

  const handleImageChange = (image) => {
    setCurrentImage(image);
    setHotspots(image.hotspots || []);
  };

  const handleHotspotClick = async (selectedImageId) => {
    const clickedImage = await images.find(
      (image) => image.id === parseInt(selectedImageId)
    );
    setCurrentImage(clickedImage);
  };

  const handleImageDelete = (e, id) => {
    dispatch(delete_image(id));
  };

  const generateHotsports = (hotSpots) => {
    return hotSpots?.hotspots?.map((item, key) => {
      return (
        <Pannellum.Hotspot
          key={key}
          handleClick={() => handleHotspotClick(item.imageId)}
          hotSpotDebug={true}
          type={item.type}
          cssClass="moveScene"
          pitch={item.pitch}
          yaw={item.yaw}
          previewTitle={item.imageId}
          text={item?.title}
          previewAuthor="Shubham Athawane"
        />
      );
    });
  };

  useEffect(() => {
    setImageId(selectedImageId);
    setPointer(getPointer);
  }, [selectedImageId, getPointer, images]);

  return (
    <Grid container spacing={2} style={{paddingBottom:"8rem", paddingLeft:'1rem'}}>
      {/* Left Side: Pannellum Viewer and Images */}
      <Grid item xs={10}>
        <div>
          {currentImage && (
            <Pannellum
              width="100%"
              height="500px"
              ref={panImage}
              onMouseup={(e) => handleHotSpotSelection(e)}
              image={currentImage.file}
              hfov={200}
              autoLoad
              showControls={true}
              showFullscreenCtrl={true}
              showZoomCtrl={true}
              orientationOnByDefault={true}>
              {/* Render hotspots */}
              {generateHotsports(currentImage)}
            </Pannellum>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <p style={{ marginBottom: "3px" }}>{currentImage?.title}</p>
          </div>
        </div>

        {/* Images Section */}
        <div style={{ overflowX: `${currentImage? "auto":""}`, paddingBottom: "50px" }}>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: `${currentImage?"nowrap":"wrap"}`, gap: "2rem" }}>
            {images.map((item) => (
              <Grid item key={item.id} style={{ flex: "0 0 auto" }}>
                <div style={{ position: "relative" }}>
                  <img
                    src={item.file}
                    alt={`Image ${item.id}`}
                    width={270}
                    height={160}
                    onClick={() => setCurrentImage(item)}
                    style={{
                      cursor: "pointer",
                      border:
                        item.id === currentImage?.id
                          ? "2px solid #007bff"
                          : "1px solid transparent",
                    }}
                  />
                  {item?.hotspots?.length > 0 && (
                    <>
                      <div
                        style={{
                          width: "100%",
                          position: "absolute",
                          bottom: "3px",
                          background: "rgb(0,0,0)",
                          background:
                            "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%)",
                        }}>
                        <p
                          style={{
                            color: "white",
                            marginLeft: "5px",
                            bottom: "2",
                          }}>
                          {item?.hotspots?.length} Hotspots
                        </p>
                      </div>
                    </>
                  )}

                  <button
                    style={{
                      position: "absolute",
                      border: "none",
                      top: "0",
                      right: "0",
                      background: "transparent",
                      opacity: "75%",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => handleImageDelete(e, item.id)}>
                    <CloseIcon />
                  </button>
                </div>
              </Grid>
            ))}
          </div>
        </div>
      </Grid>

      {/* Right Side: Selection Box and Save Button */}
      <Grid item xs={2}>
        <div>
          <InputLabel id="demo-simple-select-label">
            Choose an Image:
          </InputLabel>
          <Select onChange={handleSelect} value={selectedImageId} fullWidth>
            {images.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.title}
              </MenuItem>
            ))}
          </Select>
          <InputLabel id="demo-simple-select-label">
            Choose Hotspot type:
          </InputLabel>

          <Select
            onChange={handleHotspotTypeChange}
            value={hotspotType}
            fullWidth>
            {hotspotTypeArr.map((item) => (
              <MenuItem key={item.id} value={item.type}>
                {item.type}
              </MenuItem>
            ))}
          </Select>

          {hotspotType === "info" && (
            <TextField
              label="Enter title for hotspot"
              variant="outlined"
              fullWidth
              sx={{ mt: 2 }}
              onChange={(e) => setTitle(e.target.value)}
            />
          )}

          <Button
            variant="contained"
            onClick={(e) => handleSaveHotspot(e, selectedImageId, getPointer)}
            fullWidth
            sx={{ mt: 2 }}>
            Save Hotspot
          </Button>
          <Box   sx={{ pr: { sm: 1, xs: 0 }, position: "relative" }}>
          <Button
            variant="contained"
            disabled={isLoading ? true : false}
            onClick={(e) => vrTourAddHandle(e)}
            fullWidth
            sx={{ mt: 2 }}>
            Submit
          </Button>
          {isLoading && (
                  <CircularProgress
                    style={{
                      height:'30px',
                      width:'30px',
                      marginTop: "20px",
                      position: "absolute",
                      bottom: "2px",
                      left: "5px",
                    }}
                  />
                )}
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};

export default VrSettingsPage;
