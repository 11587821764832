import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Counter_frm from "./counter_frm";
import { counterGetAxios } from "../../utils/counter/axios";
import { Tooltip } from "@mui/material";
const Counter = ({ name }) => {
  const [counterArr, setcounterArr] = useState([]);
  const [drawerDisplay, setDrowerDisplay] = useState(false);
  // const [isEdit, setIsEdit] = useState(false);
  const [counterId, setcounterId] = useState({
    id: 0,
    yearOfExperience: "",
    projectTaken: "",
  });

  const counterFetchData = async () => {
    const user = await localStorage.getItem("userDetails");
    await counterGetAxios(JSON.parse(user))
      .then(async (res) => {
        setcounterArr(res.data.counter);
      })
      .catch((err) => {
        // alert
      });
  };
  useEffect(() => {
    counterFetchData();
  }, []);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={6}>
          <Typography gutterBottom variant="h6" component="div">
            {name}
          </Typography>
        </Grid>
        <Grid item xs={6} align="right">
          <Counter_frm
            counterArr={counterArr}
            counterId={counterId}
            drawerDisplay={drawerDisplay}
            setDrowerDisplay={() => {
             
              drawerDisplay ? setDrowerDisplay(false) : setDrowerDisplay(true);
            }}
            counterFetchData={counterFetchData}
          />
        </Grid>
      </Grid>

      <Divider />

      <Box sx={{ width: "100%" }} mt={3}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {counterArr.map((item, index) => (
            <Grid key={"counterkey" + index} item xs={12} md={6} lg={4}>
              <Card sx={{position:'relative'}}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Year Of Experience: {item.yearOfExperience}
                  </Typography>
                  <Typography gutterBottom variant="h5" component="div">
                    Project Taken: {item.projectTaken}
                  </Typography>
                </CardContent>
                <Divider />
                <CardActions sx={{position:'absolute', top:'10px',right:'0'}}>
                <Tooltip title="Edit" arrow>
                  <Button
                     size="small"
                    onClick={() => {
                      // setIsEdit(true);
                      setDrowerDisplay(true);
                      setcounterId({
                        ...counterId,
                        id: item.id,
                        yearOfExperience: item.yearOfExperience,
                        projectTaken: item.projectTaken,
                      });
                    }}
                  >
                    <EditIcon />
                  </Button>
                  </Tooltip>
                
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default Counter;
