import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Picture from "../../assets/img/thumb1.jpg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import Rating from "@mui/material/Rating";
import Testimonial_frm from "./testimonial_frm";
import { Tooltip } from "@mui/material";
import EkIcon from "../../assets/img/favicon.png";
import { styled } from "@mui/material/styles";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  testimonialsDeleteAxios,
  testimonialsGetAxios,
} from "../../utils/testimonials/axios";
import { DOMAIN } from "../../utils/api";
import Swal from "sweetalert2";
import { TimeStampToDate } from "../../common/DateAndTime";
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Testimonials = ({ name }) => {
  const [expanded, setExpanded] = useState(false);
  const [TestimonialArr, setTestimonialArr] = useState([]);
  const [drawerDisplay, setDrowerDisplay] = useState(false);
  const [disabledSubButton, setDisabledSubButton] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [TestimonialstId, setTestimonialstId] = useState({
    id: 0,
    title: "",
    rating: "",
    designation: "",
    description: "",
    image: "",
  });
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const testimonialsFetchData = async () => {
    const data = await localStorage.getItem("userDetails");
    await testimonialsGetAxios(JSON.parse(data))
      .then(async (res) => {
        setTestimonialArr(res.data.testimonials);
      })
      .catch((err) => {
        console.log("err ", err);
        // alert
      });
  };
  useEffect(() => {
    testimonialsFetchData();
  }, []);

  
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={6}>
          <Typography gutterBottom variant="h6" component="div">
            {name}
          </Typography>
        </Grid>
        <Grid item xs={6} align="right">
          <Testimonial_frm
            TestimonialstId={TestimonialstId}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            drawerDisplay={drawerDisplay}
            setDisabledSubButton={setDisabledSubButton}
            disabledSubButton={disabledSubButton}
            setDrowerDisplay={() => {
              drawerDisplay ? setDrowerDisplay(false) : setDrowerDisplay(true);
            }}
            testimonialsFetchData={testimonialsFetchData}
          />
        </Grid>
      </Grid>

      <Divider />

      <Box sx={{ width: "100%", height: "100%" }} mt={3}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {TestimonialArr.map((item, index) => (
            <Grid key={"testimonialKey" + index} item lg={4} sm={6}>
              <Card>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <CardHeader
                    avatar={
                      <CardMedia
                        component="img"
                        height="40"
                        width="40"
                        image={EkIcon}
                      />
                    }
                    title={item.title}
                    subheader={item.designation}
                  />
                  <Box sx={{display:'flex'}} >
                    <Tooltip title="Edit" arrow>
                      <Button
                        size="small"
                        onClick={() => {
                          setDrowerDisplay(true);
                          setIsEdit(true);
                          setDisabledSubButton(false);
                          setTestimonialstId({
                            ...TestimonialstId,
                            id: item.id,
                            title: item.title,
                            rating: item.rating,
                            designation: item.designation,
                            description: item.description,
                            image: item.photoPath,
                          });
                        }}
                      >
                        <EditIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Delete" arrow>
                      <Button
                        size="small"
                        onClick={async () => {
                          Swal.fire({
                            title: "Do you really want to delete?",
                            showCancelButton: true,
                            confirmButtonText: "Confirm",
                          }).then(async (result) => {
                            if (result.isConfirmed) {
                              await testimonialsDeleteAxios(item.id)
                                .then(() => {
                                  Swal.fire(
                                    "Deleted Successfully!",
                                    "",
                                    "success"
                                  );
                                  testimonialsFetchData();
                                })
                                .catch((err) => {
                                  console.log("Error", err);
                                  Swal.fire("Oops!", "", err);
                                });
                            }
                          });
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </Tooltip>
                  </Box>
                </Box>
                <CardMedia
                  component="img"
                  height="194"
                  image={DOMAIN + item.photoPath}
                  alt="Paella dish"
                />
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Rating name="read-only" value={item.rating} readOnly />
                  <Typography>{TimeStampToDate(item.created_at)}</Typography>
                </CardContent>
                {/* <CardActions disableSpacing>
                  <Tooltip title="Expand More" arrow>
                    <ExpandMore
                      expand={expanded}
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </Tooltip>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Typography paragraph>{item.description}</Typography>
                  </CardContent>
                </Collapse> */}
                <Accordion
                  sx={{
                    ":before": { display: "none" },

                    border: "none",
                    boxShadow: "none",
                    borderTop: "none",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    id="panel1-header"
                  ></AccordionSummary>
                  <AccordionDetails>{item.description}</AccordionDetails>
                </Accordion>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default Testimonials;
