import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.light" align="center" {...props}>
      {"Copyright © "}
      <Link
        style={{ color: "#ff9100" }}
        to={"http://www.ekattastudio.com/"}
        target={"_blank"}
      >
        Ekatta Studio
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const Footer = () => {
  return (
    <>
      <Box
        sx={{
          background: "#1976d2",
          textAlign: "right",
          p: "6px",
          color: "white",
          position: "fixed",
          mb: "0",
          width:"100%",
          bottom: "0",
          left: "0",
          zIndex:"1"
        }}
      >
        <Copyright sx={{ mt: 2, mb: 2 }} />
      </Box>
    </>
  );
};

export default Footer;
