import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  add_image,
  add_imageURL,
  update_img_title,
  delete_image,
} from "../../redux/ImageSlice/ImageSlice";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { PhotoCamera } from "@mui/icons-material";
const UploadAndDisplay = () => {
  const dispatch = useDispatch();

  const images = useSelector((state) => state.VRImages.Images);
  const imagesURL = useSelector((state) => state.VRImages.ImagesURL);

  const [imageState, setImages] = React.useState({});

  useEffect(() => {
    setImages(imagesURL);
  }, [images]);

  const generateUniqId = () => {
    const randomTwoDigitNumber = Math.floor(Math.random() * 90) + 10;

    return randomTwoDigitNumber;
  };

  const onImageChange = (e) => {

    const newImages = Array.from(e.target.files).map((image) => ({
      file: window.URL.createObjectURL(image),
      id: generateUniqId(),
    }));

    dispatch(add_image(newImages));
  };

  const handleInputTextChange = (e, id) => {
  
    const newTitle = e.target.value;
    dispatch(update_img_title({ id, title: newTitle }));
  };

  const handleImageDelete = (e, id) => {
    dispatch(delete_image(id));
  };

  return (
    <div>
   
      <Button sx={{mb:2}} variant="outlined" component="label">
        Upload <PhotoCamera />*
        <input onChange={onImageChange} multiple hidden accept="image/*" type="file" />
      </Button>
      {images?.map((item, key) => (
        <div key={key}>
          <div style={{ position: "relative" }}>
            <Card>
              <CardMedia sx={{ height: "200px" }} image={item.file} />
            </Card>
            {/* <img src={item.file} alt="image not found" width={"250px"} /> */}
            <button
              style={{
                position: "absolute",
                border: "none",
                top: "0",
                right: "0",
              }}
              onClick={(e) => handleImageDelete(e, item.id)}
            >
              X
            </button>
          </div>
          <TextField
            type="text"
            sx={{ paddingTop: "10px", paddingBottom: "10px", width: "100%" }}
            placeholder="Enter title"
            onChange={(e) => handleInputTextChange(e, item.id)}
          />
        </div>
      ))}
    </div>
  );
};

export default UploadAndDisplay;
