import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Card,
  CardMedia,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";
import { PhotoCamera } from "@mui/icons-material";
import { DOMAIN } from "../../utils/api";
import {
  servicesAddAxios,
  servicesUpdateAxios,
} from "../../utils/services/axios";
const Services_frm = (props) => {
  const [picEdited, setPicEdited] = useState(false);
  const [imageContainer, setImageContainer] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    top: false,
  });

  const [formData, setFormData] = useState({
    title: "",
    image: "",
  });

  useEffect(() => {
    setFormData({ ...props.serviceId, image: props.serviceId.image });
  }, [props.serviceId]);

  const setEverythingEmpty = () => {
    setFormData({
      title: "",
      image: "",
    });
    setImageContainer({});
  };

  const serviceUpdateHandle = async () => {
    const fm = new FormData();
    let params = { ...formData };
    Object.keys(params).forEach((key) => {
      fm.append(key, params[key]);
    });
    setIsLoading(true)
    await servicesUpdateAxios(fm)
      .then((res) => {
        props.servicesFetchData();
        props.setDrowerDisplay();

        Swal.fire({
          icon: "success",
          title: "Service Updated successfully",
          // text: "Updated successfully",
        });
        setIsLoading(false)
        let data = { ...formData };
        delete data["id"];
        setFormData(data);
      })
      .catch((err) => {
        console.log("err ", err);
        setIsLoading(false)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
        });
      });
  };

  const serviceAddHandle = async () => {
    if (formData.title === "") {
      Swal.fire({
        icon: "error",
        title: "Please enter a title",
      });
      props.setDrowerDisplay();
    } else if (formData.image === "") {
      Swal.fire({
        icon: "error",
        title: "Please select an image",
      });
      props.setDrowerDisplay();
    } else {
      const fm = new FormData();
      let params = { ...formData };
      setIsLoading(true)
      Object.keys(params).forEach((key) => {
        fm.append(key, params[key]);
      });
      await servicesAddAxios(fm)
        .then((res) => {
          props.servicesFetchData();
          props.setDrowerDisplay();
          formData.title = "";
          setImageContainer({});
          setPicEdited(false);
          setIsLoading(false)
          props.setDisabledSubButton(false);
          Swal.fire({
            icon: "success",
            title: "Service Added successfully",
            // text: "Service Added successfully",
          });
        })
        .catch((err) => {
          console.log("err ", err);
          setIsLoading(false)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
        });
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            variant="contained"
            onClick={() => {
              props.setIsEdit(false);
              props.setDrowerDisplay();
              props.setDisabledSubButton(false);
            }}
            endIcon={<AddCircleOutlineIcon />}
          >
            Add Services
          </Button>
          <Drawer
            anchor={anchor}
            open={props.drawerDisplay}
            onClose={(e) => {
              props.setDrowerDisplay(e);
              setEverythingEmpty();
            }}
          >
            <Box sx={{ width: 400 }} role="presentation">
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {" "}
                <Button
                  onClick={() => {
                    props.setDrowerDisplay();
                    setEverythingEmpty();
                  }}
                >
                  <CloseIcon />
                </Button>
              </Box>
              <Grid
                container
                p={3}
                justifyContent="center"
                alignItems="center"
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12}>
                  <TextField
                    sx={{ width: "100%" }}
                    id="outlined-basic"
                    label="Title*"
                    variant="outlined"
                    value={formData.title}
                    onChange={(e) => {
                      setFormData({ ...formData, title: e.target.value });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button sx={{mb:2}} variant="outlined" component="label">
                    Upload
                    <PhotoCamera />
                    <input
                      onChange={(e) => {
                        let imgD = URL.createObjectURL(e.target.files[0]);
                        setImageContainer(imgD);
                        setPicEdited(true);
                        setFormData({ ...formData, image: e.target.files[0] });
                      }}
                      hidden
                      accept="image/*"
                      type="file"
                    />
                  </Button>
                  <Card>
                    <CardMedia
                      sx={{ height: "200px" }}
                      image={
                        picEdited ? imageContainer : DOMAIN + formData.image
                      }
                      title="My Image"
                    />
                  </Card>
                  <Typography variant="body2" color={"red"}>Image size should not exceed 2MB.</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    disabled={isLoading ? true : false}
                    onClick={(e) => {
                      props.setIsEdit(false);
                      formData.id
                        ? serviceUpdateHandle(e)
                        : serviceAddHandle(e);
                      props.setDisabledSubButton(true);
                    }}
                    variant="contained"
                    sx={{ width: "100%" }}
                  >
                    {props.isEdit? "Update": "Submit"}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default Services_frm;
