import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import About_frm from "./about_frm";
import { aboutGetAxios } from "../../utils/about/axios";
import { Tooltip } from "@mui/material";

import { styled } from "@mui/material/styles";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EkIcon from '../../assets/img/favicon.png';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function About({ name }) {
  const [expanded, setExpanded] = useState(false);

  const [aboutArr, setAboutArr] = useState([]);
  const [drawerDisplay, setDrowerDisplay] = useState(false);
  const [aboutId, setAboutId] = useState({
    id: 0,
    title: "",
    description: "",
  });

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const aboutFetchData = async () => {
    const user = await localStorage.getItem("userDetails");
    await aboutGetAxios(JSON.parse(user))
      .then(async (res) => {
   
        setAboutArr(res.data.about);
      })
      .catch((err) => {
     
        // alert
      });
  };
  useEffect(() => {
    aboutFetchData();
  }, []);
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={6}>
          <Typography gutterBottom variant="h6" component="div">
            {name}
          </Typography>
        </Grid>
        <Grid item xs={6} align="right">
          <About_frm
            aboutArr={aboutArr}
            aboutId={aboutId}
            drawerDisplay={drawerDisplay}
            setDrowerDisplay={() => {
       
              drawerDisplay ? setDrowerDisplay(false) : setDrowerDisplay(true);
            }}
            aboutFetchData={aboutFetchData}
          />
        </Grid>
      </Grid>

      <Divider />

      <Box sx={{ width: "100%", height:"100%" }} mt={3}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 6 }}>
          {aboutArr.map((item, index) => (
            <Grid key={"aboutkey" + index} item lg={6} md={6} sm={8} xs={12}>
              <Card>
                <Box sx={{ display: "flex", justifyContent:'space-between' }}>
                  <CardHeader
                    avatar={
                      <CardMedia component="img"  height="40" width="40" image={EkIcon} />  
                    }
                    title={item.title}
                    subheader={item.created_at.split("T")[0]}
                  />
                  <Tooltip title="Edit" arrow>
                    <Button
                      size="small"
                      onClick={() => {
                        setDrowerDisplay(true);
                        setAboutId({
                          ...aboutId,
                          id: item.id,
                          title: item.title,
                          description: item.description,
                        });
                      }}
                    >
                      <EditIcon />
                    </Button>
                  </Tooltip>
                </Box>

                <CardContent>
                  <Typography
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "2",
                      WebkitBoxOrient: "vertical",
                    }}
                    variant="body2"
                    color="text.secondary"
                  >
                    {item.description}
                  </Typography>
                </CardContent>
                <CardActions disableSpacing>
                  <Tooltip title="Expand More" arrow>
                    <ExpandMore
                      expand={expanded}
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </Tooltip>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Typography paragraph>{item.description}</Typography>
                  </CardContent>
                </Collapse>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}
