import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Feature_frm from "./feature_frm";
import {
  featureGetAxios,
  featureDeleteAxios,
} from "../../utils/features/axios";
import { Tooltip } from "@mui/material";
import Swal from "sweetalert2";
import { styled } from "@mui/material/styles";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EkIcon from "../../assets/img/favicon.png";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Feature = ({ name }) => {
  const [expanded, setExpanded] = useState(false);
  const [drawerDisplay, setDrowerDisplay] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState([]);
  const [featureId, setfeatureId] = useState({
    id: 0,
    title: "",
    description: "",
  });
  const handleExpandClick = (clickedIndex) => {
    setExpanded(!expanded);
    if (open.includes(clickedIndex)) {
      const openCpy = open.filter((element) => {
        return element != clickedIndex;
      });
      setOpen(openCpy);
    } else {
      const openCopy = [...open];
      openCopy.push(clickedIndex);
      setOpen(openCopy);
    }
  };
  const [featureArr, setFeatureArr] = useState([]);
  const featureFetchData = async () => {
    const user = await localStorage.getItem("userDetails");
    setLoading(true);
    await featureGetAxios(JSON.parse(user))
      .then(async (res) => {
        setFeatureArr(res.data.feature);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err ", err);
        // alert
      });
  };
  useEffect(() => {
    featureFetchData();
  }, []);
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={6}>
          <Typography gutterBottom variant="h6" component="div">
            {name}
          </Typography>
        </Grid>
        <Grid item xs={6} align="right">
          <Feature_frm
            featureId={featureId}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            featureArr={featureArr}
            drawerDisplay={drawerDisplay}
            setDrowerDisplay={() => {
              drawerDisplay ? setDrowerDisplay(false) : setDrowerDisplay(true);
            }}
            featureFetchData={featureFetchData}
          />
        </Grid>
      </Grid>

      <Divider />

      <Box sx={{ width: "100%", height: "100%" }} mt={3}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {!loading ? (
            featureArr !== null ? (
              <>
                {featureArr.map((item, index) => (
                  <Grid key={"featurekey" + index} item lg={4} md={6} xs={12}>
                    {/* <Card sx={{ height: "100%" }}> */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Card
                        sx={{
                          width: "100%",
                          heigth:"100%",
                          display: "flex",
                          justifyContent: "space-between",
                          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <CardHeader
                          avatar={
                            <CardMedia
                              component="img"
                              height="30"
                              width="30"
                              image={EkIcon}
                            />
                          }
                          title={item.title}
                          subheader={item.created_at.split("T")[0]}
                        />
                        <Box mt={2} sx={{display:'flex'}}>
                          <Tooltip title="Edit" arrow>
                            <Button
                              size="small"
                              onClick={() => {
                                setDrowerDisplay(true);
                                setIsEdit(true)
                                setfeatureId({
                                  ...featureId,
                                  id: item.id,
                                  title: item.title,
                                  description: item.description,
                                });
                              }}
                            >
                              <EditIcon />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Delete" arrow>
                            <Button
                              size="small"
                              onClick={async () => {
                                Swal.fire({
                                  title: "Do you really want to delete?",
                                  showCancelButton: true,
                                  confirmButtonText: "Confirm",
                                }).then(async (result) => {
                                  if (result.isConfirmed) {
                                    await featureDeleteAxios(item.id)
                                      .then(() => {
                                        Swal.fire(
                                          "Deleted Successfully!",
                                          "",
                                          "success"
                                        );
                                        featureFetchData();
                                      })
                                      .catch((err) => {
                                        console.log("Error", err);
                                        Swal.fire("Oops!", "", err);
                                      });
                                  }
                                });
                              }}
                            >
                              <DeleteIcon />
                            </Button>
                          </Tooltip>
                        </Box>
                      </Card>
                    </Box>

                    <div
                      style={{
                        boxShadow:
                          "0px 1px 1px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <div style={{ minHeight: "60px", padding: "10px" }}>
                        <Typography
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                          }}
                          variant="body2"
                          color="text.secondary"
                        >
                          {item.description}
                        </Typography>
                      </div>

                      {/* <CardActions disableSpacing>
                      <Tooltip title="Expand More" arrow>
                        <ExpandMore
                          expand={expanded}
                          onClick={() => {
                            handleExpandClick(index);
                          }}
                          // onClick={() => setOpen(open === index ? -1 : index)}
                          aria-expanded={expanded}
                          aria-label="show more"
                        >
                          <ExpandMoreIcon />
                        </ExpandMore>
                      </Tooltip>
                    </CardActions>
                    <Collapse
                      in={open.includes(index)}
                      timeout="auto"
                      unmountOnExit
                    >
                      <CardContent>
                        <Typography paragraph>{item.description}</Typography>
                      </CardContent>
                    </Collapse> */}
                      {/* </Card> */}
                      <Accordion
                        sx={{
                          ":before": { display: "none" },

                          border: "none",
                          boxShadow: "none",
                          borderTop: "none",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          id="panel1-header"
                        ></AccordionSummary>
                        <AccordionDetails>{item.description}</AccordionDetails>
                      </Accordion>
                    </div>
                  </Grid>
                ))}
              </>
            ) : (
              <>
                <p>Error in loading!</p>
              </>
            )
          ) : (
            <>
              {Array.from({ length: 4 }).map((_, index) => (
                <Grid key={"skeletonKey" + index} item lg={3} md={6} xs={12}>
                  <Card>
                    <Skeleton height={120} />
                    <CardContent>
                      <Skeleton height={20} width="80%" />
                      <Skeleton height={20} width="100%" />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default Feature;
