import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Card,
  CardMedia,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";
import { PhotoCamera } from "@mui/icons-material";
import { DOMAIN } from "../../utils/api";
import {
  testimonialsAddAxios,
  testimonialsUpdateAxios,
} from "../../utils/testimonials/axios";
const Testimonial_frm = (props) => {
  const [picEdited, setPicEdited] = useState(false);
  const [imageContainer, setImageContainer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    top: false,
  });

  const [formData, setFormData] = useState({
    title: "",
    photoPath: "",
    designation: "",
    rating: "",
    description: "",
  });

  const setEverythingEmpty = () => {
    setFormData({
      title: "",
      photoPath: "",
      designation: "",
      rating: "",
      description: "",
    });
    setImageContainer({});
  };

  useEffect(() => {
    setFormData({
      ...props.TestimonialstId,
      photoPath: props.TestimonialstId.photoPath,
    });
  }, [props.TestimonialstId]);

  const testimonialsUpdateHandle = async () => {
    setIsLoading(true)
    const fm = new FormData();
    let params = { ...formData };
    Object.keys(params).forEach((key) => {
      fm.append(key, params[key]);
    });
    await testimonialsUpdateAxios(fm)
      .then((res) => {
        props.testimonialsFetchData();
        props.setDrowerDisplay();
        props.setDisabledSubButton(false);
        Swal.fire({
          icon: "success",
          title: "Testimonial Updated successfully",
          // text: "Updated successfully",
        });
        setIsLoading(false)
        let data = { ...formData };
        delete data["id"];
        setFormData(data);
      })

      .catch((err) => {
        console.log("err ", err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
        });
        setIsLoading(false)
      });
  };

  const testimonialsAddHandle = async () => {
    setIsLoading(true)
    try {
      if (formData.title === "") {
        Swal.fire({
          icon: "error",
          title: "Please enter a title",
        });
        props.setDrowerDisplay();
      } else if (formData.description === "") {
        Swal.fire({
          icon: "error",
          title: "Please enter a description",
        });
        props.setDrowerDisplay();
      } else if (formData.image === "") {
        Swal.fire({
          icon: "error",
          title: "Please select an image",
        });
        props.setDrowerDisplay();
      } else if (formData.designation === "") {
        Swal.fire({
          icon: "error",
          title: "Please enter a designation",
        });
        props.setDrowerDisplay();
      } else if (formData.rating === "") {
        Swal.fire({
          icon: "error",
          title: "Please give the rating",
        });
        props.setDrowerDisplay();
      }
      const fm = new FormData();
      let params = { ...formData };
      Object.keys(params).forEach((key) => {
        fm.append(key, params[key]);
      });
      await testimonialsAddAxios(fm);
      props.testimonialsFetchData();
      props.setDrowerDisplay();
      props.setDisabledSubButton(false);
      Swal.fire({
        icon: "success",
        title: "Testimonial Added successfully",
        // text: "Added successfully",
      });
      setIsLoading(false)
      setFormData({
        title: "",
        photoPath: "",
        designation: "",
        rating: "",
        description: "",
      });
      setImageContainer({});
    } catch (err) {
      console.log("err ", err);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err.response.data.message,
      });
      setIsLoading(false)
    }
  };
  

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            variant="contained"
            onClick={() => {
              props.setDrowerDisplay();
              props.setIsEdit(false);
              props.setDisabledSubButton(false);
            }}
            endIcon={<AddCircleOutlineIcon />}
          >
            Add Testimonial
          </Button>
          <Drawer
            anchor={anchor}
            open={props.drawerDisplay}
            onClose={(e) => {
              props.setDrowerDisplay(e);
              setEverythingEmpty();
            }}
          >
            <Box sx={{ width: 400 }} role="presentation">
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {" "}
                <Button
                  onClick={(e) => {
                    props.setDrowerDisplay(e);
                    setEverythingEmpty();
                  }}
                >
                  <CloseIcon />
                </Button>
              </Box>
              <Grid
                container
                p={3}
                justifyContent="center"
                alignItems="center"
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12}>
                  <TextField
                    sx={{ width: "100%" }}
                    id="outlined-basic"
                    label="Title"
                    required
                    variant="outlined"
                    value={formData.title}
                    onChange={(e) => {
                      setFormData({ ...formData, title: e.target.value });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ width: "100%" }}
                    id="outlined-basic"
                    label="Designation"
                    required
                    variant="outlined"
                    InputLabelProps={{
                      required: true,
                    }}
                    value={formData.designation}
                    onChange={(e) => {
                      setFormData({ ...formData, designation: e.target.value });
                      
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ width: "100%" }}
                    id="outlined-basic"
                    label="Rating"
                    type={"number"}
                    required
                    variant="outlined"
                    value={formData.rating}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (
                        input === "" ||
                        (parseInt(input) > 0 && parseInt(input) <= 5)
                      ) {
                        setFormData({ ...formData, rating: e.target.value });
                        
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ width: "100%" }}
                    id="outlined-multiline-static"
                    label="Description"
                    multiline
                    required
                    rows={4}
                    value={formData.description}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (
                        input === "" ||
                        (parseInt(input) > 0 && parseInt(input) <= 5)
                      ) {
                      }
                      setFormData({ ...formData, description: e.target.value });
                      
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button sx={{mb:2}} variant="outlined" component="label">
                    Upload
                    <PhotoCamera />*
                    <input
                      onChange={(e) => {
                        let imgD = URL.createObjectURL(e.target.files[0]);
                        setImageContainer(imgD);
                        setPicEdited(true);
                        setFormData({ ...formData, image: e.target.files[0] });
                      }}
                      hidden
                      accept="image/*"
                      type="file"
                    />
                  </Button>

                  <Card>
                    <CardMedia
                      sx={{ height: "200px" }}
                      image={
                        picEdited ? imageContainer : DOMAIN + formData.image
                      }
                      title="My Image"
                    />
                  </Card>
                  <Typography variant="body2" color={"red"}>Image size should not exceed 2MB.</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    disabled={isLoading ? true : false}
                    onClick={(e) => {
                      props.setIsEdit(false);
                      formData.id
                        ? testimonialsUpdateHandle(e)
                        : testimonialsAddHandle(e);
                      props.setDisabledSubButton(true);
                    }}
                    variant="contained"
                    sx={{ width: "100%" }}
                  >
                    {props.isEdit?"Update": "Submit"}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default Testimonial_frm;
