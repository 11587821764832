import axios from "../axios";
import { ADDHOME, DELETEHOME, GETHOME, UPDATEHOME } from "./api";

export const homeGetAxios = async () => {
 
    const res = await axios.get(GETHOME);
    return res;
  };
  export const homeAddAxios = async (params) => {
   
    const res = await axios.post(ADDHOME, params);
    return res;
  };
  export const homeUpdateAxios = async (params) => {
   
    const res = await axios.post(UPDATEHOME, params);
    return res;
  };
  export const homeDeleteAxios = async (params) => {
   
    const res = await axios.get(DELETEHOME+'/'+ params);
    return res;
  };