import axios from "../axios";
import { ADDPROJECT, DELETEPROJECT, GETPROJECT, UPDATEPROJECT } from "./api";

export const projectGetAxios = async () => {
 
    const res = await axios.get(GETPROJECT);
    return res;
  };
  export const projectAddAxios = async (params) => {
   
    const res = await axios.post(ADDPROJECT, params);
    return res;
  };
  export const projectUpdateAxios = async (params) => { 
    const res = await axios.post(UPDATEPROJECT, params);
    return res;
  };
  export const projectDeleteAxios = async (params) => { 
    const res = await axios.get(DELETEPROJECT+'/'+ params);
    return res;
  };