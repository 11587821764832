import axios from "../axios";
import { ADDCOUNTER, GETCOUNTER, UPDATECOUNTER } from "./api";

export const counterGetAxios = async () => {
 
    const res = await axios.get(GETCOUNTER);
    return res;
  };
  export const counterAddAxios = async (params) => {
   
    const res = await axios.post(ADDCOUNTER, params);
    return res;
  };
  export const counterUpdatedAxios = async (params) => {
   
    const res = await axios.post(UPDATECOUNTER, params);
    return res;
  };