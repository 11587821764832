import { Box, Button, Grid } from "@mui/material";
import React from "react";
import TextField from "@mui/material/TextField";
import { shadows } from '@mui/system';

const Addslide = () => {
  return (
    <>
      {/* <Box sx={{ width: "100%" , boxShadow: 3, borderRadius: 2}} p={3}>
        <Grid container  justifyContent="center" alignItems="center" rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item md={4}>
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
            />
          </Grid>
          <Grid item md={4}>
             <TextField
              id="outlined-basic"
              label="Sub-Title"
              variant="outlined"
            />
          </Grid>
          <Grid item md={4}>
          <Button variant="contained" component="label">
              Upload Slide File
              <input type="file" hidden />
            </Button>          
          </Grid>
        </Grid>
      </Box> */}
    </>
  );
};

export default Addslide;
