import axios from "../axios";
import { GETFOLLOWUP, UPDATEFOLLOWUP } from "./api";
export const followupGetAxios = async () => { 
    const res = await axios.get(GETFOLLOWUP);
    return res;
  };

  export const followupUpdateAxios = async (params) => { 
    const res = await axios.post(UPDATEFOLLOWUP,params);
    return res;
  };