import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  Divider,
  Grid,
  Switch,
  Typography,
} from "@mui/material";

import Vrtour_frm from "./vrtour_frm";
import { DOMAIN } from "../../utils/api";
import Swal from "sweetalert2";
import { vrInHomeAddAxios, vrTourDeleteAxios, vrTourGetAxios, vrWebTourGetAxios } from "../../utils/vrtour/axios";
import { Tooltip } from "@mui/material";
import Vrtour_form from "./Vrtourform";
import { CardHeader } from "@mui/material";

const VRTour = (props) => {
  const [vrTourArr, setvrTourArr] = useState([]);
  const [drawerDisplay, setDrowerDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vrCount, setVrCount] = useState();
  // const [disabledSubButton, setDisabledSubButton] = useState(false);
  const [vrTourId, setvrTourId] = useState({
    id: 0,
    thumbnail: "",
    preview: "",
    panorama: "",
  });

  const clearFormData = () => {
    setvrTourId({
      id: 0,
      thumbnail: "",
      preview: "",
      panorama: "",
    });
  };

  const vrTourFetchData = async () => {
    const data = await localStorage.getItem("userDetails");
    setLoading(true);
    await vrTourGetAxios(JSON.parse(data))
      .then(async (res) => {
     
        setvrTourArr(res.data.VrTour);
        setVrCount(res?.data)
        setLoading(false);
      })
      .catch((err) => {
        console.log("err ", err);
        // alert
      });
  };
  useEffect(() => {
    vrTourFetchData();
  }, []);

  
  const handleToggle = async (itemId, checked) => {
    try {
      const forHomeValue = checked ? 1 : 0;
      const response = await vrInHomeAddAxios({ vrTourId: itemId, for_home: forHomeValue });
  Swal.fire({
    icon: "success",
    title: response.data.message,
  });
      vrTourFetchData()
    } catch (error) {
      console.error("Error:", error);
    }
  };

 
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={6}>
          <Typography gutterBottom variant="h6" component="div">
            {props.name}
          </Typography>
        </Grid>
        <Grid item xs={6} align="right">
          <Vrtour_form
            vrTourId={vrTourId}
            props={props}
            drawerDisplay={drawerDisplay}
            setDrowerDisplay={() => {
              drawerDisplay ? setDrowerDisplay(false) : setDrowerDisplay(true);
            }}
            vrTourFetchData={vrTourFetchData}
          />
        </Grid>
      </Grid>

      <Divider />
      {!loading ? (
        vrTourArr !== null ? (
          <>
            <Box sx={{ width: "100%" }} mt={3}>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {vrTourArr.map((item, index) => (
                  <Grid key={"vrtourkey" + index} item lg={4} sm={6} xs={12}>
                    <Card sx={{ padding: 1, position: "relative" }}>
                      <Typography
                        gutterBottom
                        variant="body1"
                        component="div"
                        sx={{ marginBottom: "-5px", fontWeight:'bold', pb:1 }}
                      >
                        {item.title}
                      </Typography>
                      <Grid
                        container
                        rowSpacing={2}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid item xs={4}>
                          <CardMedia
                            sx={{
                              height: 140,
                              boxShadow:
                                "inset 0 0 0 2000px rgb(25 118 210 / 37%)",
                            }}
                            image={DOMAIN + item.thumbnail}
                            title="bunglowthumbnail"
                          />
                          <Typography
                            gutterBottom
                            variant="body1"
                            component="div"
                          >
                            Thumbnail
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <CardMedia
                            sx={{
                              height: 140,
                              boxShadow:
                                "inset 0 0 0 2000px rgb(25 118 210 / 37%)",
                            }}
                            image={DOMAIN + item.preview}
                            title="bunglowpreview"
                          />
                          <Typography
                            gutterBottom
                            variant="body1"
                            component="div"
                          >
                            Preview
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <CardMedia
                            sx={{
                              height: 140,
                              boxShadow:
                                "inset 0 0 0 2000px rgb(25 118 210 / 37%)",
                            }}
                            image={DOMAIN + item.panorama}
                            title="bunglowpranorama"
                          />
                          <Typography
                            gutterBottom
                            variant="body1"
                            component="div"
                          >
                            Panorama
                          </Typography>
                        </Grid>
                      </Grid>
                      <CardActions
                        sx={{
                          position: "absolute",
                          top: "-8px",
                          right: "0",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems:'center',
                            width: "100%",
                            // marginTop: "20px",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{ color: "black", ml: "10px" }}
                          >
                            {item.created_at.split("T")[0]}
                          </Typography>
                          <Box>
                            <Tooltip title="Add To home page" arrow>
                              <Switch
                              disabled={vrCount?.forHomeCount>=3 && item.for_home==0}
                              
                                checked={item.for_home=="0" ? false : true}
                                onChange={(e) => {
                                  handleToggle(item.id, e.target.checked)
                                  let tempVrTourArr=[...vrTourArr]
                                  tempVrTourArr[index] = { ...tempVrTourArr[index], for_home: e.target.checked ? '1' : '0' };
                                  setvrTourArr(tempVrTourArr)
                                }} 
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </Tooltip>
                            <Tooltip title="Delete" arrow>
                              <Button
                                style={{ minWidth: "auto" }}
                                size="small"
                                onClick={async () => {
                                  Swal.fire({
                                    title: "Do you really want to delete?",
                                    showCancelButton: true,
                                    confirmButtonText: "Confirm",
                                  }).then(async (result) => {
                                    if (result.isConfirmed) {
                                      await vrTourDeleteAxios(item.id)
                                        .then(() => {
                                          Swal.fire(
                                            "VR Deleted Successfully!",
                                            "",
                                            "success"
                                          );
                                          vrTourFetchData();
                                        })
                                        .catch((err) => {
                                          console.log("Error", err);
                                          Swal.fire("Oops!", "", err);
                                        });
                                    }
                                  });
                                }}
                              >
                                <DeleteIcon sx={{ color: "red" }} />
                              </Button>
                            </Tooltip>
                          </Box>
                        </Box>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>{" "}
          </>
        ) : (
          <>
            {" "}
            <p>Error While laoding the data</p>{" "}
          </>
        )
      ) : (
        <>
          <Box sx={{ width: "100%" }} mt={3}>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {Array.from({ length: 3 }).map((_, index) => (
                <Grid key={"skeletonKey" + index} item lg={4} sm={6} xs={12}>
                  <Card sx={{ padding: 1, position: "relative" }}>
                    <Skeleton height={150} />
                    <Skeleton height={25} mt={2} style={{ marginTop: 10 }} />
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default VRTour;
