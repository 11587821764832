// import axios from "axios";
import {
  BASEURL,
  LOGIN,
} from "./api";
import origin from "axios";
export const axios = origin.create({
  baseURL: BASEURL,
});
export const loginAxios = async (params) => {
  const res = await axios.post(LOGIN, params);
  return res;
};

axios.interceptors.request.use(
  async (config) => {
   
    const user = await localStorage.getItem("userDetails");
    if (user !== null) {
      const token = JSON.parse(user).token;
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
    }

    return config;
  }
  // async (error) => {
  //     Promise.reject(error);
  //     await store.dispatch(stoploading());
  //     await store.dispatch(
  //         setSnackbar({
  //             open: false,
  //             msg: "something went wrong",
  //             sevarity: "error",
  //         })
  //     );
  // }
);
export default axios;
