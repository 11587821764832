import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Grid, IconButton, TextField, Typography } from "@mui/material";
import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";
import {
  counterAddAxios,
  counterUpdatedAxios,
} from "../../utils/counter/axios";
const Counter_frm = (props) => {
  const [state, setState] = useState({
    top: false,
  });

  const [formData, setFormData] = useState({
    yearOfExperience: "",
    projectTaken: "",
  });

  useEffect(() => {
   
    setFormData(props.counterId);
  }, [props.counterId]);

  const counterUpdateHandle = async () => {

    await counterUpdatedAxios(formData)
      .then((res) => {
        props.counterFetchData();
        props.setDrowerDisplay();

        Swal.fire({
          icon: "success",
          title: "Counter Updated successfully",
          // text: 'Updated successfully',
        });

        let data = { ...formData };
        delete data["id"];
        setFormData(data);
      })
      .catch((err) => {
   
        // alert
      });
  };

  const counterAddHandle = async () => {
    if (formData.yearOfExperience === "") {
      Swal.fire({
        icon: "error",
        title: "Please enter experience year",
      });
      props.setDrowerDisplay();
    } else if (formData.projectTaken === "") {
      Swal.fire({
        icon: "error",
        title: "Please enter project count",
      });
      props.setDrowerDisplay();
    } else {
      await counterAddAxios(formData)
        .then((res) => {
          props.counterFetchData();
          props.setDrowerDisplay();
          Swal.fire({
            icon: "success",
            title: "Counter Added successfully",
            // text: "Added successfully",
          });
        })
        .catch((err) => {
         
        });
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          {props.counterArr.length < 1 && (
            <Button
              variant="contained"
              onClick={() => {
              
                props.setDrowerDisplay();
              }}
              endIcon={<AddCircleOutlineIcon />}
            >
              Add Counter
            </Button>
          )}
          <Drawer
            anchor={anchor}
            open={props.drawerDisplay}
            onClose={props.setDrowerDisplay}
          >
            <Box sx={{ width: 400 }} role="presentation">
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {" "}
                <Button onClick={props.setDrowerDisplay}>
                  <CloseIcon />
                </Button>
              </Box>
              <Grid
                container
                p={3}
                justifyContent="center"
                alignItems="center"
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12}>
                  <TextField
                    sx={{ width: "100%" }}
                    id="outlined-basic"
                    label="Years of experience*"
                    type={"number"}
                    variant="outlined"
                    value={formData.yearOfExperience}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (input === "" || parseInt(input) > 0) {
                        setFormData({
                          ...formData,
                          yearOfExperience: e.target.value,
                        });
                      }
                     
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ width: "100%" }}
                    id="outlined-multiline-static"
                    label="Project Taken*"
                    variant="outlined"
                    type={"number"}
                    value={formData.projectTaken}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (input === "" || parseInt(input) > 0)
                        setFormData({
                          ...formData,
                          projectTaken: input,
                        });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={
                      formData.id ? counterUpdateHandle : counterAddHandle
                    }
                    variant="contained"
                    sx={{ width: "100%" }}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default Counter_frm;
