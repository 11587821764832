import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EkIcon from "../../assets/img/favicon.png";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import Project_frm from "./project_frm";
import { DOMAIN } from "../../utils/api";
import Swal from "sweetalert2";
import { projectDeleteAxios, projectGetAxios } from "../../utils/project/axios";
import { Tooltip } from "@mui/material";
// import Skeleton from "react-loading-skeleton";
// import "react-loading-skeleton/dist/skeleton.css";
import { styled } from "@mui/material/styles";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Loader from "../../assets/Loader/preloader.svg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
const Projects_exp = ({ name }) => {
  const arrayPattern = /^\[.*\]$/;
  // const [currentProject, setCurrentProject] = useState({});
  const [projectArr, setprojectArr] = useState([]);
  const [drawerDisplay, setDrowerDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expandedItems, setExpandedItems] = useState([]);
  const [disabledSubButton, setDisabledSubButton] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [projectId, setprojectId] = useState({
    id: 0,
    title: "",
    description: "",
    image: "",
  });
  const handleExpandClick = (index) => {
    const newExpandedItems = [...expandedItems];
    newExpandedItems[index] = !newExpandedItems[index];
    setExpandedItems(newExpandedItems);
  };
  const projectFetchData = async () => {
    const data = await localStorage.getItem("userDetails");
    setLoading(true);
    await projectGetAxios(JSON.parse(data))
      .then(async (res) => {
        setprojectArr(res.data.Project);
        setLoading(false);
        setExpandedItems(new Array(res.data.Project.length).fill(false));
      })
      .catch((err) => {
        console.log("err ", err);
        // alert
      });
  };
  useEffect(() => {
    projectFetchData();
  }, []);
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={6}>
          <Typography gutterBottom variant="h6" component="div">
            {name}
          </Typography>
        </Grid>
        <Grid item xs={6} align="right">
          <Project_frm
            projectId={projectId}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            drawerDisplay={drawerDisplay}
            setDisabledSubButton={setDisabledSubButton}
            disabledSubButton={disabledSubButton}
            setDrowerDisplay={() => {
              drawerDisplay ? setDrowerDisplay(false) : setDrowerDisplay(true);
            }}
            projectFetchData={projectFetchData}
          />
        </Grid>
      </Grid>
      <Divider />
      {!loading ? (
        projectArr !== null ? (
          <>
            <Box sx={{ width: "100%", height: "100%" }} mt={3}>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {projectArr.map((item, index) => (
                  <Grid key={"projectkey" + index} item lg={4} md={6}>
                    {/* <Card sx={{ height: "100%" }}> */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Card
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <CardHeader
                          avatar={
                            <CardMedia
                              component="img"
                              height="40"
                              width="40"
                              image={EkIcon}
                            />
                          }
                          sx={{
                            width:"240px",
                            overflow:"hidden",
                            whiteSpace:"nowrap",
                            textOverflow:'ellipsis'
                          }}
                          title={item.title}
                          subheader={item.created_at.split("T")[0]}
                        />
                        <Box sx={{ marginTop: "20px", display:"flex" }}>
                          <Tooltip title="Edit" arrow>
                            <Button
                              size="small"
                              sx={{ marginRight: " -20px !important" }}
                              onClick={() => {
                                setIsEdit(true);
                                setDrowerDisplay(true);
                                setDisabledSubButton(false);
                                setprojectId({
                                  ...projectId,
                                  id: item.id,
                                  title: item.title,
                                  description: item.description,
                                  image:arrayPattern.test(item.photoPath)?[...JSON.parse(item.photoPath)]: [item.photoPath],
                                });
                              }}
                            >
                              <EditIcon />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Delete" arrow>
                            <Button
                              size="small"
                              onClick={async () => {
                                Swal.fire({
                                  title: "Do you really want to delete?",
                                  showCancelButton: true,
                                  confirmButtonText: "Confirm",
                                }).then(async (result) => {
                                  if (result.isConfirmed) {
                                    await projectDeleteAxios(item.id)
                                      .then(() => {
                                        Swal.fire(
                                          "Project Deleted Successfully!",
                                          "",
                                          "success"
                                        );
                                        projectFetchData();
                                      })
                                      .catch((err) => {
                                        console.log("Error", err);
                                        Swal.fire("Oops!", "", err);
                                      });
                                  }
                                });
                              }}
                            >
                              <DeleteIcon />
                            </Button>
                          </Tooltip>
                        </Box>
                      </Card>
                    </Box>
                    {/* {!Array.isArray(item.photoPath) && (
                      <CardMedia
                        component="img"
                        height="194"
                        // image={DOMAIN + "uploads\/projects\/65eacc3541596.png"}
                        image={DOMAIN + item.photoPath}
                        alt="Paella dish"
                      />
                    )} */}

                    {!arrayPattern.test(item.photoPath) && (
                      <>
                        <img
                          src={DOMAIN + item.photoPath}
                          style={{ height: "200px", width: "100%" }}
                        />
                      </>
                    )}
                    {arrayPattern.test(item.photoPath) && (
                      <>
                        <img
                          src={DOMAIN + JSON.parse(item.photoPath)[0]}
                          style={{ height: "200px", width: "100%" }}
                        />
                      </>
                    )}
                    <div
                      style={{
                        boxShadow:
                          "0px 1px 1px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <div style={{ minHeight: "60px", padding: "10px" }}>
                        <Typography
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                          }}
                          variant="body2"
                          color="text.secondary"
                        >
                          {item.description}
                        </Typography>
                      </div>
                      {/* <CardActions disableSpacing>
                        <Tooltip title="Expand More" arrow>
                          <ExpandMore
                            expand={expandedItems[index]}
                            onClick={() => handleExpandClick(index)}
                            aria-expanded={expandedItems[index]}
                            aria-label="show more"
                          >
                            <ExpandMoreIcon />
                          </ExpandMore>
                        </Tooltip>
                      </CardActions>

                      <Collapse
                        in={expandedItems[index]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <eCardContent>
                          <Typography paragraph>{item.description}</Typography>
                        </eCardContent>
                      </Collapse> */}
                      {/* </Card> */}
                      <Accordion
                        sx={{
                          ":before": { display: "none" },

                          border: "none",
                          boxShadow: "none",
                          borderTop: "none",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          id="panel1-header"
                        ></AccordionSummary>
                        <AccordionDetails>{item.description}</AccordionDetails>
                      </Accordion>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Box>{" "}
          </>
        ) : (
          <>
            {" "}
            <p>Error While laoding the data</p>{" "}
          </>
        )
      ) : (
        <div style={{ zIndex: -1 }}>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {[1, 2, 3].map((_, index) => (
              <Grid key={"skeleton" + index} item lg={4} md={6}>
                <Card>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "",
                    }}
                  >
                    <CardHeader
                      avatar={
                        <Skeleton variant="circular" width={80} height={40} />
                      }
                      title={<Skeleton height={20} width="80%" />}
                      subheader={<Skeleton height={15} width="40%" />}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "20px",
                        marginRight: "20px",
                      }}
                    >
                      <Skeleton variant="rectangular" width={30} height={30} />
                      <Skeleton variant="rectangular" width={30} height={30} />
                    </Box>
                  </Box>
                  <Skeleton variant="rectangular" height={194} />
                  <CardContent>
                    <Skeleton height={20} width="80%" />
                    <Skeleton height={20} width="100%" />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </>
  );
};

export default Projects_exp;
