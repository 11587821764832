import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import LockClockIcon from "@mui/icons-material/LockClock";
import DeckIcon from "@mui/icons-material/Deck";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import ThreeDRotationIcon from "@mui/icons-material/ThreeDRotation";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AddTaskIcon from "@mui/icons-material/AddTask";
import ReviewsOutlinedIcon from "@mui/icons-material/ReviewsOutlined";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router";
import routes, { DashbordRoutes } from "../routes";
import { getTitle } from "../utils/utils";
import { AccountCircle } from "@mui/icons-material";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import { Menu, MenuItem } from "@mui/material";
import { routePath } from "../constants/routePath";
import Logo from "../assets/img/logo.png";
import Footer from "./footer";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Navigation() {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (location.pathname.includes("/*")) navigate(routePath.HOME);
  }, []);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography sx={{ flexGrow: 1 }} variant="h6" noWrap component="div">
            {getTitle()}
          </Typography>
        
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={async () => {
                await localStorage.clear();
                navigate(routePath.ROOT);
                document.title = "Ekatta Studio Admin";
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Box component="img" alt="logo" src={Logo} />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {[
            { title: "Home", routing:"Home", icon: <CottageOutlinedIcon />, clicked: false },
            { title: "About", routing:"About", icon: <InfoOutlinedIcon />, clicked: false },
            {
              title: "Services",
              routing:"Services",
              icon: <SettingsSuggestIcon />,
              clicked: false,
            },
            { title: "Counter", routing:"Counter", icon: <LockClockIcon />, clicked: false },
            { title: "Feature", routing:"Feature", icon: <DeckIcon />, clicked: false },
            { title: "Projects", routing:"Projects", icon: <ArchitectureIcon />, clicked: false },
            { title: "VRTour", routing:"VRTour", icon: <ThreeDRotationIcon />, clicked: false },
            { title: "Contact", routing:"Enquiry", icon: <HelpOutlineIcon />, clicked: false },
            { title: "Follow Up", routing:"FollowUp", icon: <AddTaskIcon />, clicked: false },
            {
              title: "Testimonials",
              routing: 'Testimonials',
              icon: <ReviewsOutlinedIcon />,
              clicked: false,
            },
          ].map((item, index) => (
            <ListItem key={item.title} disablePadding>
              <ListItemButton
                sx={{
                  "&:hover":{
                    backgroundColor:'#0068cf',
                    color:'#fff',
                    "& .hover_color":{
                      color:'#fff',
                    },
                  },
                  "& .hover_color":{
                    color: location.pathname.split("/")[2] === item.routing?.toLowerCase() ? '#fff' :'#000',
                  },
                  background:
                    location.pathname.split("/")[2] === item.routing?.toLowerCase()
                      ? "#1976d2"
                      : "",
                  color:
                    location.pathname.split("/")[2] === item.routing?.toLowerCase()
                      ? "white"
                      : "",
                }}
                onClick={() => {
                  
                  document.title = item.title;
                  navigate(item.routing?.toLowerCase());
                }}
              >
                <ListItemIcon
                className="hover_color"
                  sx={{
                    
                    color:
                      location.pathname.split("/")[2] ===
                      item.title.toLowerCase()
                        ? "#fff"
                        : "",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main open={open} style={{width:"80%"}}>
        <DrawerHeader />
        <Routes>
          {DashbordRoutes.map(({ path, name, Component }, index) => (
            <Route
              key={"routekey" + index}
              path={path}
              element={<Component name={name} />}
            />
          ))}
        </Routes>
        <Footer open={open} />
      </Main>
    </Box>
  );
}
