import axios from "../axios";
import { ADDTESTIMONIALS, DELETETESTIMONIALS, GETTESTIMONIALS, UPDATETESTIMONIALS } from "./api";

export const testimonialsGetAxios = async () => { 
    const res = await axios.get(GETTESTIMONIALS);
    return res;
  };

  export const testimonialsAddAxios = async (params) => {   
    const res = await axios.post(ADDTESTIMONIALS, params);
    return res;
  };

  export const testimonialsUpdateAxios = async (params) => { 
    const res = await axios.post(UPDATETESTIMONIALS, params);
    return res;
  };

  export const testimonialsDeleteAxios = async (params) => { 
    const res = await axios.get(DELETETESTIMONIALS+'/'+ params);
    return res;
  };