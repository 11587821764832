import "./App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import routes from "./routes";
import { useEffect } from "react";
// import { useHistory } from 'react-router-dom';
function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const checkUser = async () => {
    const user = await localStorage.getItem("userDetails");
    if (user == null) {
      navigate("/");
    }
  };
  useEffect(() => {
    checkUser();
  }, [location]);
  return (
    <div>
      <Routes>
        {routes.map(({ path, name, Component }, index) => (
          <Route
            key={"routekeysec" + index}
            path={path}
            element={<Component name={name} />}
          />
        ))}
      </Routes>
    </div>
  );
}

export default App;
