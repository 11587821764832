import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Bunglow from "../../assets/img/bunglow.png";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Services_frm from "./services_frm";
import { DOMAIN } from "../../utils/api";
import Swal from "sweetalert2";
import {
  servicesDeleteAxios,
  servicesGetAxios,
} from "../../utils/services/axios";
import { Tooltip } from "@mui/material";
export default function Services({ name }) {
  const [aboutArr, setAboutArr] = useState([]);
  const [drawerDisplay, setDrowerDisplay] = useState(false);
  const [disabledSubButton, setDisabledSubButton] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [serviceId, setserviceId] = useState({
    id: 0,
    title: "",
    image: "",
  });

  const servicesFetchData = async () => {
    const data = await localStorage.getItem("userDetails");
    await servicesGetAxios(JSON.parse(data))
      .then(async (res) => {
        setAboutArr(res.data.services);
      })
      .catch((err) => {
        console.log("err ", err);
        // alert
      });
  };
  useEffect(() => {
    servicesFetchData();
  }, []);
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={6}>
          <Typography gutterBottom variant="h6" component="div">
            {name}
          </Typography>
        </Grid>
        <Grid item xs={6} align="right">
          <Services_frm
            serviceId={serviceId}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            setDisabledSubButton={setDisabledSubButton}
            disabledSubButton={disabledSubButton}
            drawerDisplay={drawerDisplay}
            setDrowerDisplay={() => {
              drawerDisplay ? setDrowerDisplay(false) : setDrowerDisplay(true);
            }}
            servicesFetchData={servicesFetchData}
          />
        </Grid>
      </Grid>

      <Divider />

      <Box sx={{ width: "100%", height:"100%" }} mt={3}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {aboutArr.map((item, index) => (
            <Grid key={item.id + index} item xs={12} md={6} lg={4}>
              <Card sx={{ position: "relative" }}>
                <CardMedia
                  sx={{
                    height: 200,
                    boxShadow: "inset 0 0 0 2000px rgb(25 118 210 / 37%)",
                  }}
                  image={DOMAIN + item.photoPath}
                  title="bunglow"
                />
                <Divider />
                <CardActions
                  sx={{
                    position: "absolute",
                    top: "10px",
                    right: "0",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{ color: "white", mb: "0" }}
                        gutterBottom
                        variant="h6"
                        component="div"
                      >
                        {item.title}
                      </Typography>
                      <Typography variant="body2" sx={{ color: "white" }}>
                        {item.created_at.split("T")[0]}
                      </Typography>
                    </Box>
                    <Stack spacing={2} direction="row" flexWrap="nowrap" sx={{pr:2}}>
                      <Tooltip title="Edit" arrow>
                          <EditIcon sx={{ color: "white" }} onClick={() => {
                            setIsEdit(true);
                            setDrowerDisplay(true);
                            setDisabledSubButton(false);
                            setserviceId({
                              ...serviceId,
                              id: item.id,
                              title: item.title,
                              image: item.photoPath,
                            });
                          }}/>
                      </Tooltip>
                      <Tooltip title="Delete" arrow>
                        
                          <DeleteIcon sx={{ color: "white" }} 
                           onClick={async () => {
                            Swal.fire({
                              title: "Do you really want to delete?",
                              showCancelButton: true,
                              confirmButtonText: "Confirm",
                            }).then(async (result) => {
                              if (result.isConfirmed) {
                                await servicesDeleteAxios(item.id)
                                  .then(() => {
                                    Swal.fire(
                                      "Service Deleted Successfully!",
                                      "",
                                      "success"
                                    );
                                    servicesFetchData();
                                  })
                                  .catch((err) => {
                                    console.log("Error", err);
                                    Swal.fire("Oops!", "", err);
                                  });
                              }
                            });
                          }}/>
                      </Tooltip>
                    </Stack>
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}
