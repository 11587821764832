import { ABOUT, ADDABOUT, UPDATEABOUT } from "./api";
import axios from "../axios";

export const aboutGetAxios = async () => {
    const res = await axios.get(ABOUT);
    return res;
  };
  export const aboutAddAxios = async (params) => { 
    const res = await axios.post(ADDABOUT, params);
    return res;
  };
  export const aboutUpdateAxios = async (params) => { 
    const res = await axios.post(UPDATEABOUT, params);
    return res;
  };